import { Card, Typography } from "@material-tailwind/react";
import { FC, ReactNode } from "react";
import { useAuth } from "../../../hooks";
import Loader from "../../Loader";

interface AuthCardProps {
  children: any;
  title: string;
  subtitle?: ReactNode | string;
  loading?: boolean;
  icon?: ReactNode;
}

const AuthCard: FC<AuthCardProps> = ({
  children,
  title,
  subtitle,
  loading,
  icon,
}) => {
  const storageNotLoaded = !useAuth().storageLoaded;
  return (
    <Card className="pb-12 pt-4 px-6 max-w-[450px] w-full">
      {loading || storageNotLoaded ? (
        <div className="w-full min-h-[200px] flex justify-center items-center flex-col">
          <span>Please wait</span>
          <Loader color="blue-600" />
        </div>
      ) : (
        <div className="my-6 mx-3">
          <div className="flex justify-center mb-2">{icon && icon}</div>
          <Typography variant="h3" className="mb-2 text-center text-2xl">
            {title}
          </Typography>
          {subtitle ? (
            <Typography as="div" className="mb-4 text-center">
              {subtitle}
            </Typography>
          ) : null}
          <section>{children}</section>
        </div>
      )}
    </Card>
  );
};

export default AuthCard;
