import { useEffect, useMemo, useState } from 'react';
import { ArrowDownCircleOutline, ArrowRedoOutline } from 'react-ionicons';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Copier, Loader, Table } from '../../../components';
import PageWrapper from '../../../components/PageWrapper';
import { TabNavList, TabNavListItm, TabContent, TabContentItem } from '../../../components/Tabs';
import { poolBalanceColumn } from '../../../constants/pool';
import { txHistoryColumn } from '../../../constants/txHistory';
import { useAccounts, useConfig } from '../../../hooks';
import DepositModal from './components/DepositModal';

const LiquidityPool = () => {
  const { address } = useParams();
  const { networks } = useConfig();
  const [searchParams] = useSearchParams();
  const {
    accounts,
    fetchAll,
    isFetching,
    returnPoolBalances,
    loadingBalances,
    fetchingAllTx,
    fetchPoolBalances,
    getPoolTransactions,
    fetchAllTransactions,
  } = useAccounts();

  const [showDepositModal, setShowDepositModal] = useState<boolean>(false);
  const networkId = useMemo(() => searchParams.get('networkId'), [searchParams]);

  const pool = useMemo(() => {
    if (!accounts || !address) return;
    return (accounts ?? []).find((a) => address.toLowerCase() === a?.address?.toLowerCase());
  }, [accounts, address]);

  const network = useMemo(
    () => networks?.find((n) => n.networkId === networkId),
    [networkId, networks]
  );

  const balances = useMemo(() => {
    if (pool?.address) return returnPoolBalances(pool.address, pool.networkId);
    return [];
  }, [pool?.address, pool?.networkId, returnPoolBalances]);

  const totalBusdBalance = useMemo(() => {
    const _total = (balances || []).map((b) => b.balanceInUsd ?? 0).reduce((a, b) => +a + +b, 0);
    return parseFloat(_total.toString()).toFixed(2);
  }, [balances]);

  const txHistory = useMemo(
    () => (!!pool ? getPoolTransactions(pool?.address!, pool?.networkId) : []),
    [getPoolTransactions, pool]
  );

  useEffect(() => {
    if (!networkId) return;
    fetchAll(networkId);
  }, [fetchAll, networkId]);

  useEffect(() => {
    if (!pool) return;
    fetchPoolBalances(pool?.address!, pool.networkId);
    fetchAllTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool]);

  return (
    <PageWrapper title='Liquidity pool accounts'>
      {!isFetching ? (
        <div className='mt-7'>
          <div className='flex justify-between gap-5'>
            <Card
              title={loadingBalances ? <Loader /> : `${totalBusdBalance}`}
              titlePrefix='USD'
              classNames='flex-grow-0 w-[auto] py-3'
              headingClassNames='text-[28px]'>
              <p className='text-xs text-gray-600'>Total pool balance</p>
            </Card>
            <div className='flex justify-end my-6 gap-5 flex-grow'>
              <div>
                <Button
                  className='py-[10px]'
                  text='Deposit'
                  icon={<ArrowDownCircleOutline color='inherit' />}
                  fullWidth
                  onClick={() => setShowDepositModal(true)}
                />
              </div>
              <div>
                <Link to={`/liquidity-pools/${address}/withdraw?networkId=${networkId}`}>
                  <Button
                    className='py-[10px]'
                    text='Withdraw'
                    icon={<ArrowRedoOutline color='inherit' />}
                    fullWidth
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className='mt-5 flex gap-5 lg:flex-row flex-col'>
            <Card title='Pool address'>
              <div className='flex justify-start gap-5 text-gray-700'>
                <p>{pool?.address}</p>
                <Copier text={pool?.address!} />
              </div>
            </Card>
            <Card title='Owner address'>
              <div className='flex justify-start gap-5 text-gray-700'>
                <p>{pool?.cexSigner}</p>
                <Copier text={pool?.cexSigner!} />
              </div>
            </Card>
            <Card title='Network'>
              <p className='text-gray-700'>{`${network?.name} (${network?.symbol})`}</p>
            </Card>
          </div>
          <div className='mt-7'>
            <TabNavList id='pool-histories'>
              <TabNavListItm
                title='Balances'
                id='pool-balances'
                controls='pool-balances-tab'
                showAsDefault
              />
              <TabNavListItm title='Transactions' id='tx-histories' controls='tx-histories-tab' />
            </TabNavList>
            <TabContent id='pool-histories-content'>
              <TabContentItem id='pool-balances-tab' controller='pool-balances' showAsDefault>
                <Table columns={poolBalanceColumn} data={balances} loading={loadingBalances} />
              </TabContentItem>
              <TabContentItem id='tx-histories-tab' controller='tx-histories'>
                <Table
                  columns={txHistoryColumn(network!)}
                  data={txHistory}
                  loading={fetchingAllTx}
                  noResultMessage='Not transaction records found!'
                />
              </TabContentItem>
            </TabContent>
          </div>
        </div>
      ) : (
        <div className='w-full flex min-h-[100px]  justify-center items-center'>
          <Loader color='blue-600' />
        </div>
      )}
      <DepositModal
        show={showDepositModal}
        toggleShow={setShowDepositModal}
        address={address!}
        network={network!}
      />
    </PageWrapper>
  );
};

export default LiquidityPool;
