import { FC, useMemo } from "react";
import { fetchPageNumbers } from "../../../../lib/table";
import CaretLeftIcon from "../../../Icons/CaretLeftIcon";
import CaretRightIcon from "../../../Icons/CaretRightIcon";
import classnames from "classnames";

interface PaginationProps {
  total: number;
  limit: number;
  neighbours: number;
  currentPage: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  previousPage: () => void;
  nextPage: () => void;
  gotoPage: (pageNumber: number) => void;
}

export enum TERMINAL_PAGE {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

const Pagination: FC<PaginationProps> = ({
  total = 0,
  limit = 10,
  neighbours = 0,
  currentPage = 1,
  canNextPage,
  canPreviousPage,
  previousPage,
  nextPage,
  gotoPage,
}) => {
  const pageNeighbours = useMemo(
    () => Math.max(0, Math.min(neighbours, 2)),
    [neighbours]
  );
  const totalPage = useMemo(() => Math.ceil(total / limit), [total, limit]);
  const pages = useMemo(
    () => fetchPageNumbers(totalPage, pageNeighbours, currentPage),
    [totalPage, pageNeighbours, currentPage]
  );

  if (!total || totalPage === 1) return null;

  return (
    <div className="flex w-full justify-end py-[38px]">
      <div className="flex mx-9 items-center flex-wrap gap-4">
        {pages.map((page, i) => {
          if (page === TERMINAL_PAGE.LEFT)
            return (
              <button
                className="svg-container mr-1 cursor-pointer"
                onClick={previousPage}
                disabled={!canPreviousPage}
                key={i}
              >
                <CaretLeftIcon />
              </button>
            );

          if (page === TERMINAL_PAGE.RIGHT)
            return (
              <button
                className="svg-container ml-1 cursor-pointer"
                onClick={nextPage}
                disabled={!canNextPage}
                key={i}
              >
                <CaretRightIcon />
              </button>
            );

          return (
            <button
              onClick={() => gotoPage(page - 1)}
              key={i}
              className={classnames([
                "border-2 rounded-[6px] cursor-pointer hover:bg-blue-100",
                {
                  "border-blue-700": currentPage === page,
                },
              ])}
            >
              <p
                className={classnames([
                  "px-[10px] py-2 text-xs",
                  {
                    "text-blue-700": currentPage === page,
                    "text-slate-600": currentPage !== page,
                  },
                ])}
              >
                {page}
              </p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Pagination;
