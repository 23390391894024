import { ButtonType, ButtonVariant } from "../components/Button";

export const variantToColorClassMap: Record<
  ButtonVariant,
  Record<ButtonType, string>
> = {
  default: {
    default: "bg-blue-700 hover:bg-blue-600",
    success: "bg-green-600 hover:bg-green-500",
    info: "bg-blue-500 hover:bg-blue-400",
    warning: "bg-yellow-600 hover:bg-yellow-500",
    danger: "bg-red-600 hover:bg-red-500",
  },
  outline: {
    default: "border-blue-700 text-blue-700 hover:bg-blue-700",
    success: "border-green-600 text-green-600 hover:bg-green-600",
    info: "border-blue-500 text-blue-500 hover:bg-blue-500",
    warning: "border-yellow-600 text-yellow-600 hover:bg-yellow-600",
    danger: "border-red-600 text-red-600 hover:bg-red-600",
  },
  soft: {
    default: "text-blue-700 bg-blue-100 hover:bg-blue-200",
    success: "text-green-600 bg-green-100 hover:bg-green-200",
    info: "text-blue-700 bg-blue-100 hover:bg-blue-200",
    warning: "text-yellow-600 bg-yellow-100 hover:bg-yellow-200",
    danger: "text-red-600 bg-red-100 hover:bg-red-200",
  },
  ghost: {
    default: "text-blue-700 hover:bg-blue-100",
    success: "text-green-600 hover:bg-green-100",
    info: "text-blue-700 hover:bg-blue-100",
    warning: "text-yellow-600 hover:bg-yellow-100",
    danger: "text-red-600 hover:bg-red-100",
  },
  text: {
    default: "text-blue-700",
    success: "text-green-600",
    info: "text-blue-700",
    warning: "text-yellow-600",
    danger: "text-red-600",
  },
};
