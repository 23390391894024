import { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "@material-tailwind/react";
import { ToastProvider } from "react-toast-notifications";
import AuthProvider from "./AuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";

import { configuration as toastConfig } from "./../config/toast";
import ConfigProvider from "./ConfigProvider";
import SignerProvider from "./SignerProvider";
import AccountProvider from "./AccountProvider";
import ApiKeyProvider from "./ApiKeyProvider";
import UrlProvider from "./UrlProvider";
import OrderProvider from "./OrderProvider";
import WithdrawalProvider from "./WithdrawalProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppProviders: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ToastProvider {...toastConfig}>
          <AuthProvider>
            <ConfigProvider>
              <SignerProvider>
                <AccountProvider>
                  <UrlProvider>
                    <ApiKeyProvider>
                      <OrderProvider>
                        <WithdrawalProvider>{children}</WithdrawalProvider>
                      </OrderProvider>
                    </ApiKeyProvider>
                  </UrlProvider>
                </AccountProvider>
              </SignerProvider>
            </ConfigProvider>
          </AuthProvider>
        </ToastProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default AppProviders;
