import { Option, Select, SelectProps } from '@material-tailwind/react';
import React, { useMemo } from 'react';
import { useConfig } from '../../hooks';

interface IProp extends Pick<SelectProps, 'value' | 'variant' | 'onChange' | 'label' | 'size' | 'disabled'> {
  allowAllFilter?: boolean;
}

interface INetworkOptions {
  value: any;
  label: string;
}

const SelectNetwork: React.FC<IProp> = ({ allowAllFilter, ...rest }) => {
  const { networks } = useConfig()
  const options = useMemo(() => {
    let _options: INetworkOptions[] = [];

    if (allowAllFilter) {
      _options.push({ value: 'all', label: 'All networks' });
    }

    for (const { networkId, name, symbol } of networks || []) {
      _options.push({
        label: `${name} (${symbol})`,
        value: networkId,
      });
    }
    return _options;
  }, [networks, allowAllFilter]);

  return (
    <Select {...rest} color='gray'>
      {options.map(({ value, label }, index) => (
        <Option value={value} key={index}>
          {label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectNetwork;
