import WalletCard from ".";
import { useAccounts } from "../../../hooks";
import { getTotalBalances } from "../../../lib/transactions";

const WalletCardsContainer = () => {
  const { customerAccounts: accounts } = useAccounts();
  const accountsBalances = getTotalBalances(accounts! || []);
  const currencies = Object.keys(accountsBalances);

  return (
    <div className="flex justify-center overflow-auto">
      {currencies.length ? (
        currencies.map((currency, index) => {
          return (
            <WalletCard
              key={currency}
              currency={currency}
              balance={accountsBalances[currency]}
            />
          );
        })
      ) : (
        <WalletCard currency={"USD"} balance={0} />
      )}
    </div>
  );
};

export default WalletCardsContainer;
