import ApiClient, { getTokenHeader } from './client';

export const getPaymentChannels = async (token: string) => {
  const { data } = await ApiClient.payouts.clients.getSupportedChannels(getTokenHeader(token));
  return data.channels
};

export const getProviders = async (token: string) => {
  const { data } = await ApiClient.payouts.providers.getAvailableProviders(getTokenHeader(token));
  return data.providers;
}