import * as Yup from "yup";
import { validateAssetAddress } from "../lib/validator";

export const newSignerValidationSchema = Yup.object().shape({
  address: Yup.string().test(
    "is-valid-address",
    "wallet address is not a valid address",
    (value) => validateAssetAddress("ETH", value || "")
  ),
  otp: Yup.string().length(6, "otp length must be 6").required(),
});
