import { Copier } from '../../../../../components';

const MenuActionCol = ({ row }: any) => {
  return (
    <div className='relative flex justify-center justify-self-center actions-intereaction cursor-pointer'>
      <Copier text={row.original.address} />
    </div>
  );
};

export default MenuActionCol;
