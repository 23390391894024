import {
  NetworkDto,
  ExchangeSvcPoolTxnSide,
  ExchangeSvcPoolTxnType,
} from '@nestcoinco/onboard-api-gateway-api-client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Fragment, useState } from 'react';
import { TableActionMenu } from '../components';
dayjs.extend(relativeTime)

type IActionMenu = React.FC<{ row: any; explorerUrl: string }>;

const TypeText: Record<ExchangeSvcPoolTxnType, string> = {
  [ExchangeSvcPoolTxnType.DEPOSIT]: 'Deposit',
  [ExchangeSvcPoolTxnType.WITHDRAWAL]: 'Withdrawal',
  [ExchangeSvcPoolTxnType.OFFRAMP_DEBIT]: 'Trade transfer',
  [ExchangeSvcPoolTxnType.OFFRAMP_REFUND]: 'Trade refund',
  [ExchangeSvcPoolTxnType.ONRAMP_DEPOSIT]: 'Trade deposit',
};

const ActionsMenu: IActionMenu = ({ row, explorerUrl }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <TableActionMenu row={row} isOpen={isOpen} setIsOpen={setIsOpen}>
      <a
        onClick={() => setIsOpen(false)}
        href={`${explorerUrl}/tx/${row.original.hash}`}
        target='_blank'
        rel='noreferrer'
        className='block p-2 text-xs rounded text-slate-600 hover:font-medium hover:bg-blue-100 hover:text-blue-700'>
        View on explorer
      </a>
    </TableActionMenu>
  );
};

export const txHistoryColumn = (network: NetworkDto) => [
  {
    Header: 'Type',
    accessor: 'txType',
    className: "text-left",
    Cell: ({ row }: any) => <span>{TypeText[row.original.txType as ExchangeSvcPoolTxnType]}</span>,
  },
  {
    Header: 'Asset',
    accessor: 'tokenSymbol',
    className: "text-left",
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    className: "text-left",
    Cell: ({ row }: any) => (
      <Fragment>
        {row.original.txSide === ExchangeSvcPoolTxnSide.CREDIT ? (
          <span className='text-green-700'>+{parseFloat(row.original.amount)}</span>
        ) : (
          <span className='text-red-700'>-{parseFloat(row.original.amount)}</span>
        )}
      </Fragment>
    ),
  },
  {
    Header: 'Age',
    accessor: 'timestamp',
    className: "text-left",
    Cell: ({ row }: any) => (
      <span>{dayjs().to(dayjs(+row.original.timestamp * 1000))}</span>
    ),
  },
  {
    Header: 'Actions',
    className: 'text-center',
    Cell: ({ row }: any) => <ActionsMenu row={row} explorerUrl={network.explorer} />,
  },
];
