import React from "react";
import { useNavigate } from "react-router";
import { ArrowBackCircle } from "react-ionicons";

const BackButton = ({ handleClick }: { handleClick?: () => void }) => {
  const navigate = useNavigate();
  return (
    <button
      className="flex items-center gap-2"
      onClick={() => {
        if (!handleClick) navigate(-1);
        else handleClick();
      }}
    >
      <ArrowBackCircle width="24px" height="24px" />
      Back
    </button>
  );
};

export default BackButton;
