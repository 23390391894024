import React, { FC } from "react";
import classnames from "classnames";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error?: string;
  bottomPad?: string;
  variant?: string;
}

const Input: FC<InputProps> = ({
  error,
  label,
  name,
  type = "text",
  placeholder,
  className,
  disabled,
  bottomPad = "5",
  variant = "light",
  ...props
}) => {
  return (
    <div className={`w-full mb-${bottomPad}`}>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor={name}
      >
        {label}
      </label>
      <input
        className={classnames([
          "appearance-none block w-full placeholder:text-gray-600 bg-transparent border-2 border-gray-400 rounded-[10px] py-[18px] px-5 mb-1 leading-tight focus:outline-none",
          className,
          {
            "!border-red-500 ": error && error.trim().length > 0,
            "bg-gray-200": disabled,
            "!border-gray-600": variant === "dark",
          },
        ])}
        id={name}
        placeholder={placeholder || label}
        type={type}
        name={name}
        disabled={disabled}
        {...props}
      />
      {error ? <p className="text-red-500 text-xs italic">{error}</p> : null}
    </div>
  );
};

export default Input;
