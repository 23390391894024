import ApiClient, { getTokenHeader } from './client';
import { getCexConfigs } from './configs';

export const getAuthorizationUrl = async (token: string): Promise<string | undefined> => {
  if(!token) return;
  const configs = await getCexConfigs(token);
  return configs?.authorizationUrl;
};

export const getWebhookUrl = async (token: string): Promise<string | undefined> => {
  if(!token) return;
  const configs = await getCexConfigs(token);
  return configs?.webhookUrl;
};

export const getAutoExecuteUrl = async (token: string): Promise<string | undefined> => {
  if(!token) return;
  const configs = await getCexConfigs(token);
  return configs?.executionUrl;
};


export const upsertTradeAuthorizationUrl = async (
  url: string,
  token: string
) => {
  await ApiClient.exchange.cex.upsertCexAuthorizationUrl({url}, getTokenHeader(token))
};

export const upsertTradeWebhookUrl = async (url: string, token: string) => {
  await ApiClient.exchange.cex.upsertCexWebhookUrl({url},  getTokenHeader(token));
};

export const upsertTradeAutoExecuteUrl = async (url: string, token: string) => {
  await ApiClient.exchange.cex.upsertTradeExecutionUrl({url},  getTokenHeader(token));
}
