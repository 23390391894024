import { useContext } from "react";
import { AccountContext } from "../../providers/AccountProvider";

const useAccount = () => {
  const accountContext = useContext(AccountContext);

  if (!accountContext)
    throw new Error(
      "PROVIDER_ERROR: useAccount can only be used within AccountContext"
    );

  return accountContext;
};

export default useAccount;
