import { FC } from "react";
import { Link } from "react-router-dom";

interface SidebarItemProps {
  to: string;
  text: string;
  onClick: () => void;
  active?: boolean;
  icon: any;
}
const SidebarItem: FC<SidebarItemProps> = ({
  to,
  text,
  active,
  onClick,
  icon: Icon,
}) => {
  return (
    <Link
      to={to}
      onClick={() => onClick()}
      className={`flex flex-col ${active ? "border-r-2 border-blue-700" : ""}`}
    >
      <li className="flex w-full  justify-between text-slate-800 hover:bg-blue-50 cursor-pointer items-center ">
        <span className={`flex px-8 items-center py-5 w-full `}>
          {Icon}
          <span className={`text-sm ml-2 ${active ? "text-blue-700" : ""}`}>
            {text}
          </span>
        </span>
      </li>
    </Link>
  );
};

export default SidebarItem;
