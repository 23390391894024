import { useState } from "react";
import { useAuth } from "../../../../hooks";
import { AvaterIcon } from "../../../Icons";

const Avatar = () => {
  const [openAvatarMenu, setOpenAvatarMenu] = useState(false);
  const { user, business, logout } = useAuth();

  return (
    <div className="cursor-pointer relative">
      <div
        className="flex gap-x-2 items-center"
        onClick={() => setOpenAvatarMenu((v) => !v)}
      >
        <div>
          {user?.firstName ? (
            <div className="w-[30px] h-[30px] grid place-items-center rounded-full bg-gray-900 text-white text-[14px]">
              {user.firstName[0]}
              {user.lastName[0] || ""}
            </div>
          ) : business?.businessLogoUrl ? (
            <img className="pl-4" src={business.businessLogoUrl} alt="avatar" />
          ) : (
            <AvaterIcon />
          )}
        </div>
        <div className="pr-4">
          <h1 className="font-semibold text-sm">
            {`${user?.firstName || ""} ${user?.lastName || ""}`}
            <span className="text-blue-700 bg-blue-50 font-medium text-[8px] p-1 rounded ml-2">
              {business?.businessName || ""}
            </span>
          </h1>
          <p className="text-xs text-slate-600 mt-1">{user?.email || ""}</p>
        </div>
      </div>

      <div
        className={`absolute w-full min-w-[242px] max-h-fit rounded overflow-hidden bg-white top-9 opacity-${
          openAvatarMenu ? "1" : "0"
        } z-[${
          openAvatarMenu ? "1" : "-1"
        }] right-0 transition-[top,opacity] duration-200 shadow-[0px_2px_5px_rgba(0,0,0,0.06),0px_4px_6px_-1px_rgba(0,0,0,0.1)]`}
      >
        <ul className="flex flex-col justify-center py-5 gap-y-5">
          <li
            onClick={() => logout()}
            className="cursor-pointer text-sm px-4 text-slate-600"
          >
            Sign Out
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Avatar;
