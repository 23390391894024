import React from "react";
import classNames from "classnames";
import { Button } from "../../../components";
import { useDepositContext } from "..";
import BackButton from "../../../components/Button/BackButton";

const withdrawalTypes = [
  {
    label: "stable coins",
    title: "Crypto Deposit",
    active: true,
  },
  {
    label: "Onboard Exchange",
    title: "Onboard Exchange",
    active: false,
  },
  {
    label: "USD Wire Transfer",
    title: "USD Wire Transfer",
    active: false,
  },
];

const DepositMethod = () => {
  const { dispatch } = useDepositContext();

  function handleClick(label: string) {
    if (label.includes("coins")) {
      dispatch({ step: "coin" });
    }
  }

  return (
    <div className=" w-[500px] mx-auto">
      <div className="mt-14">
        <BackButton />
      </div>
      <div className="mt-6 bg-white p-10 rounded-[10px]">
        <h1 className="text-center font-semibold pb-5">
          Select deposit method
        </h1>
        <div className="flex flex-col gap-3 rounded-md">
          {withdrawalTypes.map((method) => {
            const { label, active, title } = method;
            return (
              <Button
                key={label}
                onClick={() => {
                  handleClick(label);
                }}
                className={classNames(
                  "height-[150px] grid place-items-center rounded-md",
                  {
                    "text-primary-300 border border-primary-300": active,
                  }
                )}
                text={`${title} ${active ? `(${label})` : "coming soon"}`}
                disabled={!active}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DepositMethod;
