import { FC } from "react";
import { InfoCircleIcon } from "../Icons";

interface ToolTipInfoProps {
  text: string;
}

const ToolTipInfo: FC<ToolTipInfoProps> = ({ text }) => {
  return (
    <label className="inline-flex gap-x-[10px] items-center  text-sm leading-none text-gray-600 hover:underline cursor-pointer ">
      <span
        //@ts-ignore
        after={text}
        className={`transition-all before:hidden after:hidden hover:before:block hover:after:block relative
                          after:absolute after:min-w-[200px] after:text-[#475569] after:bottom-[1.75rem] after:left-[-1rem]
                          after:text-xs after:p-4 after:bg-white after:content-[attr(after)]                                 
                          after:shadow-[0px_1px_2px_rgba(0,0,0,0.07)]  before:z-10
                          before:content-[''] before:absolute before:w-0 before:h-0 before:border-r-[8px]
                          before:border-t-[10px] before:border-l-[8px] before:border-t-white 
                          before:drop-shadow-[0px_2px_2px_rgba(0,0,0,0.07)]
                          before:border-l-transparent before:border-r-transparent
                          before:left-[-0.1rem] before:bottom-[1.1rem]`}
      >
        <InfoCircleIcon />
      </span>
    </label>
  );
};

export default ToolTipInfo;
