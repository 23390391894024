import { FC } from "react";
import { HeaderGroup } from "react-table";
import classnames from "classnames";

interface TableHeadProps {
  headerGroups: HeaderGroup[];
}

const TableHead: FC<TableHeadProps> = ({ headerGroups }) => {
  return (
    <thead className="border-b border-slate-100">
      {headerGroups.map((headerGroup) => (
        <tr className="" {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, i) => (
            <th
              {...column.getHeaderProps([
                {
                  style: (column as any).style,
                },
              ])}
              className={classnames([
                "py-6 text-base font-medium leading-none text-left text-slate-600",
                (column as any)?.className,
                {
                  "pl-8 pr-6": i === 0,
                  "px-6": i !== 0,
                },
              ])}
            >
              {column.render("Header")}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHead;
