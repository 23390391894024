import { FC, useState } from "react";
import Search from "../Search";
import { useAsyncDebounce } from "react-table";

interface FiltersProps {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

const Filters: FC<FiltersProps> = ({ globalFilter, setGlobalFilter }) => {
  const [filter, setFilter] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleSetValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="flex flex-row flex-wrap  items-center justify-end mb-12 header mt-6">
      <div className=" mx-6 div ">
        <div className="flex flex-wrap w-full gap-4 mx-auto ">
          <Search value={filter} setValue={handleSetValue} />
        </div>
      </div>
    </div>
  );
};

export default Filters;
