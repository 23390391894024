import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AuthCard, Button, Input } from "../../../components";
import { useAuth, useQuery } from "../../../hooks";
import { resetPasswordSchema } from "../../../schemas/auth";

const ResetPassword = () => {
  const { request } = useQuery();
  const { resetPassword } = useAuth();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      const response: any = await resetPassword(request, values.password);

      if (response?.error) {
        return addToast(response?.error?.message as any, {
          appearance: "error",
        });
      }

      addToast("Password reset successfully. Please login", {
        appearance: "success",
      });
      navigate("/auth/login");
    },
  });

  return (
    <AuthCard
      title="Reset your password"
      subtitle="Please reset your account password below"
    >
      <form onSubmit={formik.handleSubmit}>
        <Input
          label="Enter password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          required
          type="password"
          error={formik.errors.password}
        />
        <Input
          label="Confirm password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          required
          type="password"
          error={formik.errors.confirmPassword}
        />
        <Button
          text="Reset Password"
          type="submit"
          className="mt-4 mb-1"
          fullWidth
          loading={formik.isSubmitting}
        />
      </form>
    </AuthCard>
  );
};

export default ResetPassword;
