import React, { useState } from "react";
import classNames from "classnames";
import { useDepositContext } from "..";
import { Button, Loader } from "../../../components";
import { getFundingAddress } from "../../../api/accounts";
import { useAccounts, useAuth } from "../../../hooks";
import BackButton from "../../../components/Button/BackButton";

const SelectTokenNetwork = () => {
  const { state, dispatch } = useDepositContext();
  const { authToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const account = useAccounts().customerAccounts?.[0];

  function handleClick(network: any) {
    dispatch({ selectedNetwork: network });
    setIsLoading(true);
    getFundingAddress(authToken!, account?.id!, network.code)
      .then((data) => {
        dispatch({ depositAddress: data.address });
      })
      .catch(err => {
        dispatch({ depositAddress: "" });
        console.error("Unable to load address: ", err.message);
      })
      .finally(() => {
        dispatch({ step: "address" });
        setIsLoading(false);
      });
  }

  return (
    <div className="mx-auto w-[450px]">
      <div className="mt-14">
        <BackButton handleClick={() => dispatch({ step: "coin" })} />
      </div>
      <div className="mt-6 bg-white p-10 rounded-[10px]">
        <h1 className="text-center font-semibold pb-3">Select Network</h1>
        <div className="flex flex-col gap-3">
          {isLoading ? (
            <div className="flex justify-center">
              <Loader color="blue-600" />{" "}
            </div>
          ) : (
            state.selectedToken?.networks.map((network: any) => {
              const {
                networkInfo: { name },
              } = network;
              const disabled = !network.depositAvailable;
              return (
                <Button
                  key={network.code}
                  onClick={() => handleClick(network)}
                  className={classNames(
                    "height-[150px] flex items-baseline gap-1 justify-center rounded-md  text-primary-300 border border-primary-300",
                    { "border-none": disabled }
                  )}
                  disabled={disabled}
                >
                  {name}{" "}
                  <span className="text-xs font-normal">
                    (
                    {network.networkInfo?.alias
                      ? network.networkInfo?.alias
                      : network.code}
                    )
                  </span>
                </Button>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectTokenNetwork;
