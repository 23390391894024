import {
  DocumentLockOutline,
  HomeOutline,
  KeyOutline,
  ServerOutline,
  SettingsOutline,
  CashOutline,
  WalletOutline,
  SwapHorizontalOutline,
} from "react-ionicons";

export const sidebarItems = {
  top: [
    {
      text: "Dashboard",
      to: "/",
      icon: <HomeOutline />,
    },
  ],
  bottom: [
    {
      text: "API Keys",
      to: "/api-keys",
      icon: <DocumentLockOutline />,
    },
  ],
};

export const OnboardConnectItems = {
  general: [
    {
      text: "Orders",
      to: "/orders",
      icon: <CashOutline />,
    },
    {
      text: "Settings",
      to: "/settings",
      icon: <SettingsOutline />,
    },
  ],
  serviceSetup: [
    {
      text: "Liquidity Accounts",
      to: "/liquidity-pools",
      icon: <ServerOutline />,
    },
    {
      text: "Signing Addresses",
      to: "/signers",
      icon: <KeyOutline />,
    },
  ],
};

export const OpnItems = [
  {
    text: "Wallet",
    to: "/wallet",
    icon: <WalletOutline />,
  },
  {
    text: "Trades",
    to: "/exchanges",
    icon: <SwapHorizontalOutline />,
  },
  {
    text: "Settings",
    to: "/opn/settings",
    icon: <SettingsOutline />,
  },
];
