import {
  CryptoAssetDto,
  CexConfigDto,
  FiatDto,
  NetworkDto,
  WebhookSecretDto,
} from '@nestcoinco/onboard-api-gateway-api-client';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { generateOrResetWebhookSecret, getCexConfigs, viewWebhookSecret, getErrorMessage, getConfigs } from '../../api';
import { useAuth } from '../../hooks';
import { ContextActionReturnType } from '../../typings';

interface ConfigContextProps {
  loading: boolean;
  cexConfig?: CexConfigDto;
  networks?: NetworkDto[];
  cryptoAssets?: CryptoAssetDto[];
  fiats?: FiatDto[];
  loadingConfigs: boolean;
  loadCexConfigs: () => void;
  webhookSecret?: WebhookSecretDto;
  callViewWebhookSecret: (totp: string) => Promise<void>;
  callGenerateWebhookSecret: (
    totp: string,
    regenerate?: boolean
  ) => Promise<ContextActionReturnType | void>;
}

export const ConfigContext = createContext<ConfigContextProps | null>(null);

const ConfigProvider = (prop: any) => {
  const { addToast } = useToasts();
  const { authToken } = useAuth();
  const [loading, setloading] = useState<boolean>(false);
  const [networks, setNetworks] = useState<NetworkDto[]>();
  const [cryptoAssets, setCryptoAssets] = useState<CryptoAssetDto[]>();
  const [fiats, setFiats] = useState<FiatDto[]>();
  const [loadingConfigs, setloadingConfigs] = useState(false);
  const [cexConfig, setCexConfig] = useState<CexConfigDto | undefined>();
  const [webhookSecret, setWebhookSecret] = useState<WebhookSecretDto | undefined>();

  const handleError = useCallback(
    (e: any) => {
      const error = getErrorMessage(e).message;
      addToast(error, { appearance: 'error' });
    },
    [addToast]
  );

  const loadCexConfigs = useCallback(() => {
    if (!authToken) return;
    setloadingConfigs(true);
    getCexConfigs(authToken)
      .then(setCexConfig)
      .catch(handleError)
      .finally(() => setloadingConfigs(false));
  }, [authToken, handleError]);

  const callViewWebhookSecret = useCallback(
    async (totp: string) => {
      if (!authToken) return;
      setloading(true);
      return viewWebhookSecret(authToken, totp)
        .then(setWebhookSecret)
        .catch(handleError)
        .finally(() => setloading(false));
    },
    [authToken, handleError]
  );

  const callGenerateWebhookSecret = useCallback(
    async (totp: string, regenerate?: boolean) => {
      if (!authToken) return;
      setloading(true);
      await loadCexConfigs();
      if (cexConfig?.isWebhookSecretSet && !regenerate) {
        await callViewWebhookSecret(totp);
        return;
      }
      return generateOrResetWebhookSecret(authToken, { totp, regenerate })
        .then((data) => {
          setWebhookSecret(data);
          setCexConfig((config) => ({ ...config!, isWebhookSecretSet: true }));
          addToast(regenerate ? 'Webhook secret regenerated' : 'Webhook secret generated.', { appearance: 'success' })
        })
        .catch(handleError)
        .finally(() => setloading(false));
    },
    [addToast, authToken, callViewWebhookSecret, cexConfig?.isWebhookSecretSet, handleError, loadCexConfigs]
  );

  useEffect(() => {
    getConfigs().then((config) => {
      setNetworks(config.tradeConfig?.networks);
      setCryptoAssets(config.tradeConfig?.assets);
      setFiats(config.tradeConfig?.fiats);
    })
      .catch(handleError)
  }, [handleError]);

  const values = useMemo<ConfigContextProps>(
    () => ({
      loading,
      networks,
      cryptoAssets,
      fiats,
      cexConfig,
      webhookSecret,
      loadingConfigs,
      loadCexConfigs,
      callViewWebhookSecret,
      callGenerateWebhookSecret,
    }),
    [
      callGenerateWebhookSecret,
      callViewWebhookSecret,
      cexConfig,
      loadCexConfigs,
      loading,
      loadingConfigs,
      networks,
      cryptoAssets,
      fiats,
      webhookSecret,
    ]
  );

  return <ConfigContext.Provider value={values}>{prop.children}</ConfigContext.Provider>;
};

export default ConfigProvider;
