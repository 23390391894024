import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ArrowBackOutline } from "react-ionicons";
import { useNavigate } from "react-router-dom";
import { Button, Card, Input } from "../../../components";
import PageWrapper from "../../../components/PageWrapper";
import useWithdrawal from "../../../hooks/useWithdrawal";
import { getPercentageValue } from "../../../lib/percentValue";
import { externalWithdrawalSchema } from "../../../schemas/withdrawal";

const CompleteWithdrawal = () => {
  const { selectedToken, selectedTokenNetwork, initCryptoTransfer, account } =
    useWithdrawal();

  useEffect(() => {
    if (!selectedToken || !selectedTokenNetwork) {
      navigate("/withdrawal/external");
    }
  }, []);

  const navigate = useNavigate();
  const initialValues = {
    address: "",
    amount: 0,
  };
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues,

    validationSchema: externalWithdrawalSchema(
      selectedToken,
      account?.availableBalance!
    ),
    onSubmit: async (values) => {
      setIsLoading(true);
      const data: any = await initCryptoTransfer(values.address, values.amount);

      if (!data?.error) {
        navigate("/withdrawal/external/confirm", {
          replace: true,
          state: {},
        });
        return;
      }

      setIsLoading(false);
    },
  });

  const { networkInfo, withdrawalFee, decimals } = selectedTokenNetwork || {};

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <PageWrapper title="Complete Withdrawal">
      <p className="mt-4 flex cursor-pointer" onClick={handleBack}>
        <ArrowBackOutline /> Back{" "}
      </p>
      <div className="flex justify-center w-full">
        <Card
          title="Confirm withdrawal"
          headingClassNames="mb-8 text-center"
          classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
          children={
            <>
              <p className="text-center text-sm mb-6">
                Confirm that destination supports{" "}
                <span className="font-semibold">{selectedToken}</span> on{" "}
                <span className="font-semibold">
                  {networkInfo?.name} ({networkInfo?.coinName})
                </span>
                . Withdrawals are final.
              </p>
              <form onSubmit={formik.handleSubmit} className="w-full">
                <Input
                  label="Enter Address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  required
                  autoComplete="true"
                  type="text"
                  error={formik.errors.address}
                />
                <div className="p-3" />
                <Input
                  label="Enter Amount"
                  name="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  required
                  autoComplete="true"
                  type="number"
                  error={formik.errors.amount}
                  bottomPad="2"
                />
                <div className="flex justify-end gap-4 text-sm pb-4">
                  <span
                    className="cursor-pointer"
                    data-testid="10%"
                    onClick={() =>
                      formik.setFieldValue(
                        "amount",
                        getPercentageValue(0.1, account?.availableBalance!),
                        true
                      )
                    }
                  >
                    [10%]
                  </span>{" "}
                  <span
                    className="cursor-pointer"
                    data-testid="25%"
                    onClick={() =>
                      formik.setFieldValue(
                        "amount",
                        getPercentageValue(0.25, account?.availableBalance!),
                        true
                      )
                    }
                  >
                    [25%]
                  </span>{" "}
                  <span
                    className="cursor-pointer"
                    data-testid="50%"
                    onClick={() =>
                      formik.setFieldValue(
                        "amount",
                        getPercentageValue(0.5, account?.availableBalance!),
                        true
                      )
                    }
                  >
                    [50%]
                  </span>
                  <span
                    data-testid="max"
                    className="cursor-pointer"
                    onClick={() =>
                      formik.setFieldValue(
                        "amount",
                        account?.availableBalance!,
                        true
                      )
                    }
                  >
                    [MAX]
                  </span>
                </div>
                <div>
                  <p className=" text-gray-500">
                    Your balance:{" "}
                    <span className="text-gray-900">
                      {account?.availableBalance} {selectedToken}
                    </span>
                  </p>
                  {formik.values.amount <= account?.availableBalance! && (
                    <p className="pt-2 pb-4 text-gray-500 text-sm">
                      Fee = {withdrawalFee} {selectedToken}
                      {formik.values.amount > withdrawalFee! && (
                        <span>
                          , recipient will receive{" "}
                          <span className="font-semibold">
                            {formik.values.amount - withdrawalFee!}{" "}
                            {selectedToken}
                          </span>
                        </span>
                      )}
                    </p>
                  )}
                </div>

                {account?.availableBalance! <= withdrawalFee! ? (
                  <p className="text-center text-[#dc3545] pb-4 pt-2">
                    You do not have suffienct balance
                  </p>
                ) : null}
                <Button
                  loading={isLoading}
                  className="w-full btn-primary"
                  text={"Proceed"}
                  disabled={!formik.isValid}
                />
              </form>
            </>
          }
        />
      </div>
    </PageWrapper>
  );
};

export default CompleteWithdrawal;
