import { ExchangeOrdersResponse, OrderResponse, OrderTransactionList } from '@nestcoinco/onboard-api-gateway-api-client';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import { getErrorMessage } from '../../api';
import { getOPNOrder, getOPNOrders, getOrders, IOPNOrderFilter, IOrderFilter } from '../../api/order';
import { useAuth } from '../../hooks';
import { ContextActionReturnType } from '../../typings';

interface OrderContextProps {
  loading: boolean;
  isFetching: boolean;
  recentOrders: ExchangeOrdersResponse | undefined;
  fetchRecentOrders: VoidFunction;
  fetchOrders: (
    query: IOrderFilter
  ) => Promise<ContextActionReturnType<ExchangeOrdersResponse> | void>;
  fetchOPNOrders: (
    query: IOPNOrderFilter
  ) => Promise<ContextActionReturnType<OrderTransactionList> | void>;
  fetchOPNOrder: (
    txRef: string
  ) => Promise<{ data?: OrderResponse; error?: any; } | void>;
}

export const OrderContext = createContext<OrderContextProps | null>(null);

const OrderProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { addToast } = useToasts();
  const { authToken, verified } = useAuth();
  const [recentOrders, setRecentOrders] = useState<ExchangeOrdersResponse | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const handleError = useCallback(
    (e: any) => {
      const error = getErrorMessage(e).message;
      addToast(error, { appearance: 'error' });
    },
    [addToast]
  );

  const fetchOrders = useCallback(
    async (query: IOrderFilter) => {
      try {
        if (!authToken || !verified) return;
        setLoading(true);
        const orders = await getOrders(authToken, query);
        return { data: orders };
      } catch (error) {
        return { error: getErrorMessage(error) };
      } finally {
        setLoading(false);
      }
    },
    [authToken, verified]
  );

  const fetchOPNOrders = useCallback(
    async ({page, status, size} : IOPNOrderFilter) => {
      try {
        if (!authToken || !verified) return;
        const orders = await getOPNOrders(authToken, { size, page, status });
        return { data: orders}
      } catch (e: any) {
        return { error: getErrorMessage(e) };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken, verified, getErrorMessage]
  )

  const fetchOPNOrder = useCallback(
    async (txRef: string) => {
      try {
        if (!authToken || !verified) return;
        const order = await getOPNOrder(authToken, txRef);
        return { data: order };
      } catch (e: any) {
        return { error: getErrorMessage(e) };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken, verified, getErrorMessage]
  )

  const fetchRecentOrders = useCallback(() => {
    if (!authToken || !verified) return;
    setIsFetching(true);
    getOrders(authToken, { size: 10 })
      .then(setRecentOrders)
      .catch(handleError)
      .finally(() => setIsFetching(false));
  }, [authToken, handleError, verified]);

  useEffect(() => {
    if (authToken && verified) {
      fetchRecentOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = useMemo<OrderContextProps>(
    () => ({ isFetching, loading, recentOrders, fetchOrders, fetchRecentOrders, fetchOPNOrders, fetchOPNOrder }),
    [fetchOrders, fetchRecentOrders, isFetching, loading, recentOrders, fetchOPNOrders, fetchOPNOrder]
  );

  return <OrderContext.Provider value={values}>{children}</OrderContext.Provider>;
};

export default OrderProvider;
