import React from 'react';

type IProp = React.FC<{
  error: string | string[] | any;
}>;

const FormikError: IProp = ({ error }) => {
  return !!error ? (
    <p className='text-red-500 text-xs italic'>{Array.isArray(error) ? error[0] : error}</p>
  ) : null;
};


export default FormikError;