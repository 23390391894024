import React from 'react';
import { ApiKeyContext } from '../../providers/ApiKeyProvider';

const useApiKeys = () => {
  const apiKeyContext = React.useContext(ApiKeyContext);

  if (!apiKeyContext) {
    throw new Error('PROVIDER_ERROR: useApiKeys can only be used within ApiKeyContext.');
  }

  return apiKeyContext;
};

export default useApiKeys;
