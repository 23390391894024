import { TransferStatus } from "@nestcoinco/onboard-api-gateway-api-client";
import { useEffect, useState } from "react";
import { ArrowBackOutline } from "react-ionicons";
import { useNavigate } from "react-router-dom";
import { Button, Card, Input } from "../../../components";
import PageWrapper from "../../../components/PageWrapper";
import useWithdrawal from "../../../hooks/useWithdrawal";

const ConfirmWithdrawal = () => {
  const navigate = useNavigate();
  const { confirmCryptoTransferOtp, cryptoTransferResponse, otpMessage } =
    useWithdrawal();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const isSuccess = (status: TransferStatus) => {
    return [
      TransferStatus.SUBMITTED,
      TransferStatus.AWAITING_DISBURSEMENT,
      TransferStatus.PROCESSED,
      TransferStatus.VALIDATED,
      TransferStatus.RECONCILED,
      TransferStatus.SCREENING_REQ,
    ].includes(status);
  };

  useEffect(() => {
    if (!cryptoTransferResponse || !otpMessage) {
      navigate("/withdrawal/external", {
        replace: true,
      });
    }
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);

    const otpSubmitResponse = await confirmCryptoTransferOtp(
      cryptoTransferResponse?.id!,
      otp,
      cryptoTransferResponse?.twoFaMethod?.[0]!
    );

    if (isSuccess(otpSubmitResponse?.status!)) {
      navigate("/withdrawal/success", {
        replace: true,
        state: {
          message: "Withdrawal successful",
        },
      });
    }

    setIsLoading(false);
  };

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <PageWrapper title="Confirm Withdrawal">
      <p className="mt-4 flex cursor-pointer" onClick={handleBack}><ArrowBackOutline /> Back </p>
      <div className="flex justify-center w-full">
        <Card
          title="Confirm withdrawal"
          headingClassNames="mb-8 text-center"
          classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
          children={
            <>
              <div className="w-full">
                <p className="text-center text-sm">{otpMessage}</p>
                <div className="py-10 pb-14">
                  <Input
                    placeholder="Enter OTP"
                    className="text-center"
                    name="otp"
                    value={otp}
                    onChange={(e: any) => {
                      setOtp(e.target.value);
                    }}
                    required
                    type="text"
                  />
                </div>
                <Button
                  loading={isLoading}
                  className="w-full btn-primary"
                  text={"Confirm Withdrawal"}
                  onClick={handleSubmit}
                  disabled={!otp}
                />
              </div>
            </>
          }
        />
      </div>
    </PageWrapper>
  );
};

export default ConfirmWithdrawal;
