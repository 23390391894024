import ApiClient from "./client";

export const getNetworks = async () => {
  const { data } = await ApiClient.asset.getOnboardConfigs();

  return data.tradeConfig?.networks;
};


export const getConfigs = async () => {
  const { data } = await ApiClient.asset.getOnboardConfigs();
  return data;
}
