import AddessValidator from "trezor-address-validator";
import MultiCoinValidator from "multicoin-address-validator";

export const validateAssetAddress = (
  assetType: string,
  address: string,
  network?: "testnet" | "prod"
): boolean => {
  if (assetType !== "BUSD") {
    return MultiCoinValidator.validate(address, assetType, network || "prod");
  }
  return AddessValidator.validate(address, assetType, network || "prod");
};
