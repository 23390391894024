import dayjs from 'dayjs';
import { Copier } from '../../../../components';
import { RefreshOutline, TrashOutline } from 'react-ionicons';
import { IFullKey } from '../../../../providers/ApiKeyProvider';
import React from 'react';
import { Button, Tooltip } from '@material-tailwind/react';

interface IProps {
  apiKey: IFullKey;
  deleteKey?: (credentialId: string) => void;
  refreshKey?: (credentialId: string) => void;
  showSecret?: (credentialId: string) => void;
  hideSecret?: (credentialId: string) => void;
}

const ApiKeyCard: React.FC<IProps> = ({
  apiKey,
  deleteKey,
  refreshKey,
  showSecret,
  hideSecret,
}) => {
  return (
    <div className='border-b pb-5 mb-5'>
      <div className='flex-grow-1 border p-2 rounded-md mb-4'>
        <span className='text-[0.8em] text-gray-600 border px-2 py-1 rounded-md'>Key</span>
        <p className='mt-2 flex gap-5 items-center justify-start'>
          <span className='text-12 font-bold truncate inline-block max-w-[80%]'>{apiKey?.key}</span>
          <Copier text={apiKey?.key} />
        </p>
      </div>
      {apiKey?.secret && (
        <div className='flex-grow-1 border p-2 rounded-md mb-4'>
          <span className='text-[0.8em] text-gray-600 border px-2 py-1 rounded-md'>Secret</span>
          <p className='mt-2 flex gap-5 items-center justify-start'>
            <span className='text-12 font-bold truncate inline-block max-w-[80%]'>
              {apiKey?.secret}
            </span>
            <Copier text={apiKey?.secret} />
          </p>
        </div>
      )}
      <div className='flex justify-start items-center gap-5'>
        <p className='flex-grow-1 text-[12px] font-semibold text-gray-700'>
          Created at {dayjs().to(dayjs(apiKey?.createdAt))}
        </p>

        <Tooltip content='Reset keys'>
          <RefreshOutline
            color='blue'
            cssClasses='cursor-pointer'
            onClick={() => refreshKey && refreshKey(apiKey.id)}
          />
        </Tooltip>

        <TrashOutline
          color='red'
          cssClasses='cursor-pointer'
          onClick={() => deleteKey && deleteKey(apiKey.id)}
        />
        {!apiKey?.secret ? (
          <Button variant='outlined' size='sm' onClick={() => showSecret && showSecret(apiKey.id)}>
            Show Secret
          </Button>
        ) : (
          <Button
            variant='outlined'
            size='sm'
            color='amber'
            onClick={() => hideSecret && hideSecret(apiKey.id)}>
            Hide Secret
          </Button>
        )}
      </div>
    </div>
  );
};

export default ApiKeyCard;
