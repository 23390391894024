import {
  ExchangeSvcLiquidityPoolDto,
  LiquidityPoolBalanceDto,
  ExchangeSvcLiquidityPoolTransactionHistoryDto,
  PoolWithdrawalTransactionDto,
} from '@nestcoinco/onboard-api-gateway-api-client';
import ApiClient, { getTokenHeader } from './client';

export type ExchangeLPoolHistoryDto = ExchangeSvcLiquidityPoolTransactionHistoryDto;
export interface PoolTxQueryFilter {
  networkId?: string;
  poolAddress?: string;
  tokenSymbol?: string;
  fromDate?: string;
  toDate?: string;
  skip?: number;
  limit?: number;
}

export const deployNewPool = async (networkId: string, cexSigner: string, token: string) => {
  await ApiClient.exchange.cex.deployLiquidityPool(
    {
      cexSigner,
      networkId,
    },
    getTokenHeader(token)
  );
};

export const fetchAllPools = async (
  token: string,
  networkId?: string
): Promise<ExchangeSvcLiquidityPoolDto[]> => {
  const { data } = await ApiClient.exchange.cex.queryLiquidityPools(
    networkId,
    getTokenHeader(token)
  );

  return data.liquidityPools ?? [];
};

export const getPoolBalances = async (
  authToken: string,
  address: string,
  networkId?: string
): Promise<LiquidityPoolBalanceDto[]> => {
  const { data } = await ApiClient.exchange.cex.fetchLiquidityPoolBalance(
    address,
    networkId,
    getTokenHeader(authToken)
  );
  return data.balances ?? [];
};

export const getPoolTxHistory = async (
  authToken: string,
  filter?: PoolTxQueryFilter
): Promise<ExchangeLPoolHistoryDto[]> => {
  const { data } = await ApiClient.exchange.cex.poolTransactionHistories(
    filter?.toDate,
    filter?.fromDate,
    filter?.skip,
    filter?.limit,
    filter?.networkId,
    filter?.poolAddress,
    filter?.tokenSymbol,
    getTokenHeader(authToken)
  );
  return data?.histories ?? [];
};

export const withdrawFromPool = async (
  authToken: string,
  poolAddress: string,
  networkId: string,
  tokenSymbol: string,
  amount: number
): Promise<PoolWithdrawalTransactionDto> => {
  const { data } = await ApiClient.exchange.cex.withdrawFromPool(
    poolAddress,
    {
      networkId,
      tokenSymbol,
      amount,
    },
    getTokenHeader(authToken)
  );
  return data;
};
