import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface CardProps {
  children: any;
  title?: string | ReactNode;
  titlePrefix?: string;
  classNames?: string;
  headingBorder?: boolean;
  headingClassNames?: string;
}

const Card: FC<CardProps> = ({
  children,
  title,
  classNames,
  headingBorder,
  headingClassNames,
  titlePrefix,
}) => {
  const titleClasssNames = cn([
    'text-[15px] uppercase',
    ...(headingClassNames ? [headingClassNames] : []),
    ...(headingBorder ? ['border-b border-grey-600 pb-2'] : []),
  ]);
  return (
    <div className={`bg-white rounded-[4px] w-full p-7 ${classNames}`}>
      {title ? (
        <h2 className={titleClasssNames}>
          {title}
          {titlePrefix ? <span className='text-[0.5em] ml-2'>{titlePrefix}</span> : ''}
        </h2>
      ) : null}
      <div className='my-1'>{children}</div>
    </div>
  );
};

export default Card;
