import { NetworkDto } from "@nestcoinco/onboard-api-gateway-api-client";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
dayjs.extend(relativeTime)

export const allSignersTableColumn = (networks?: NetworkDto[]) => [
  {
    Header: "Network",
    accessor: "networkId",
    className: "text-left",
    Cell: (props: any) => {
      const networkId = props?.row?.original?.networkId;
      const network = networks?.find(
        (network) => network.networkId === networkId
      );
      return <div>{network?.name}</div>;
    },
  },
  {
    Header: "Address",
    accessor: "address",
    className: "text-left",
  },
  {
    Header: "Created On",
    accessor: "createdAt",
    className: "text-center",
    Cell: ({ row }: any) => (
      <span>{dayjs().to(dayjs(row.original.createdAt))}</span>
    ),
  },
];
