import { OrderResponse } from "@nestcoinco/onboard-api-gateway-api-client";
import React, { useCallback, useEffect, useState } from "react";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import BackButton from "../../components/Button/BackButton";
import { toCommaValue } from "../../lib";
import { useAuth } from "../../hooks";
import { useToasts } from "react-toast-notifications";
import { getErrorMessage } from "../../api";
import { getClientOrderById } from "../../api/order";
import { Loader } from "../../components";
dayjs.extend(relativeTime);

type OrderPropsType = {
  orderId: string;
  setOrderId: (id: string | null) => void;
};

function Order({ orderId, setOrderId }: OrderPropsType) {
  const { authToken, verified } = useAuth();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<OrderResponse | null>(null);

  const handleError = useCallback(
    (e: any) => {
      const error = getErrorMessage(e);
      addToast(error.message, { appearance: "error" });
    },
    [addToast]
  );

  useEffect(() => {
    if (!authToken || !verified) return;
    setIsLoading(true);
    getClientOrderById(authToken, orderId!)
      .then(setData)
      .catch(handleError)
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError]);
  return (
    <>
      <div className="max-w-[500px] mx-auto pb-8">
        <div className="mb-4 mt-16">
          <BackButton handleClick={() => setOrderId(null)} />
        </div>
        <div className="border border-gray-100 rounded-md p-6 bg-white">
          <h1 className="font-semibold text-xl">Order Details</h1>
          {isLoading ? (
            <Loader color="black" />
          ) : (
            <ul>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Date
                </span>
                {dayjs().to(dayjs(data?.createdAt))}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Amount
                </span>
                USD {toCommaValue(data?.amount!)}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Description
                </span>
                Traded {data?.payoutCurrency} for USD
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">Fee</span>
                USD 1.00
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Order Type
                </span>
                {data?.type}
              </li>

              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Rate
                </span>
                {data?.payoutCurrency} {toCommaValue(data?.rate!)}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Total Amount
                </span>
                {data?.payoutCurrency}{" "}
                {toCommaValue(data?.amount! * data?.rate!)}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Account
                </span>
                Paga
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Channel
                </span>
                {data?.paymentChannel}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Recipient Name
                </span>
                {data?.recipient.firstName}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Payout Reference
                </span>
                {data?.reference}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Payout status
                </span>
                {data?.status}
              </li>
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default Order;
