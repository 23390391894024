import * as Yup from 'yup';
import { validateAssetAddress } from '../lib/validator';

export const newAccountValidationSchema = Yup.object().shape({
  address: Yup.string().test('is-valid-address', 'wallet address is not a valid address', (value) =>
    validateAssetAddress('ETH', value || '')
  ),
});

export const withdrawalValidationSchema = Yup.object().shape({
  poolAddress: Yup.string().required('Liquidity pool address is required'),
  asset: Yup.string().required('Withdrawal asset symbol is required'),
  networkId: Yup.string().required('Network is required'),
  amount: Yup.number()
    .required('Numeric amount is required')
    .positive('Amount must be more than 0'),
});
