import {
  ChangeEvent,
  createRef,
  FC,
  RefObject,
  useEffect,
  useState,
} from "react";

interface PinInputProps {
  value: string;
  onPinChange: (value: string) => void;
  type?: string;
}

const PinInput: FC<PinInputProps> = ({ value, onPinChange, type = "text" }) => {
  const [inputRefs, setInputRef] = useState<RefObject<HTMLInputElement>[]>([]);

  useEffect(() => {
    const refs = Array.apply(0, Array(6)).map((_, i) =>
      createRef<HTMLInputElement>()
    );
    setInputRef(refs);
  }, []);

  useEffect(() => {
    if (inputRefs.length && value.length < 6) {
      const currentInput = inputRefs[value.length];
      if (currentInput.current) {
        currentInput.current.focus();
      }
    } else if (inputRefs.length && value.length === 6) {
      const currentInput = inputRefs[5];
      if (currentInput.current) {
        currentInput.current.focus();
      }
    }
  }, [value, inputRefs]);

  const handleChange = (e: any) => {
    const event: ChangeEvent<HTMLInputElement> = e;
    const inputChar = event.target.value;
    if (value.length <= 5 && Number.isInteger(Number(inputChar))) {
      onPinChange(value + inputChar);
    }
  };

  const handleKeyDown = (e: any) => {
    const event: KeyboardEvent = e;
    if (event.key === "Backspace") {
      onPinChange(value.slice(0, -1));
    }
  };

  const handlePaste = (e: any) => {
    const event: ClipboardEvent = e;
    if (event.clipboardData) {
      event.clipboardData.items[0].getAsString((text) => {
        for (let i = 0; i < 6; i++) {
          onPinChange(text);
        }
      });
    }
  };

  const handleReset = (e: any) => {
    e.preventDefault();
    inputRefs.forEach((inputRef) => {
      if (inputRef.current?.value) {
        inputRef.current.value = "";
      }
    });
    onPinChange("");
  };

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div
          className="flex justify-between space-x-2 w-full"
          onPaste={handlePaste}
        >
          {Array.apply(0, Array(6)).map((_, index) => (
            <input
              key={index}
              ref={inputRefs[index]}
              autoFocus={index === 0}
              className={`border-2 border-grey-100 bg-grey-100 text-center w-12 h-12 md:h-14 md:w-14 lg:w-15 lg:h-15 title-1 rounded-xl focus:outline-none focus:bg-transparent focus:border-primary-500 ${
                value[index] ? "border-blue-600 bg-transparent" : ""
              }`}
              value={value[index] || ""}
              maxLength={1}
              type={type}
              inputMode="numeric"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          ))}
        </div>
        {value.length > 0 && (
          <button
            onClick={handleReset}
            className="text-blue-600 text-12 ml-0 mt-1 "
          >
            Clear pin
          </button>
        )}
      </div>
    </div>
  );
};

export default PinInput;
