import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TableActionMenu } from "../components";
import { toCommaValue } from "../lib";

type IBalanaceMenu = React.FC<{
  row: any
}>

const BalanceMenuAction: IBalanaceMenu = ({row}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <TableActionMenu row={row} isOpen={isOpen} setIsOpen={setIsOpen}>
        <Link
          onClick={() => setIsOpen(false)}
          to={`/liquidity-pools/${row.original.poolAddress}/withdraw?networkId=${row.original.networkId}&asset=${row.original.assetSymbol}`}
          className='block p-2 text-xs rounded text-slate-600 hover:font-medium hover:bg-blue-100 hover:text-blue-700'>
          Withdraw
        </Link>
    </TableActionMenu>
  )
}

export const poolBalanceColumn = [
  {
    Header: "Asset",
    accessor: "assetSymbol",
    className: "text-left",
  },
  {
    Header: "Balance",
    accessor: "balance",
    className: "text-left",
    Cell: ({row}: any) => (
      <span>{toCommaValue(row.original.balance, 8)}</span>
    )
  },
  {
    Header: "Balance (USD)",
    accessor: "balanceInUsd",
    className: "text-left",
    Cell: ({row}: any) => (
      <span>{toCommaValue(row.original.balanceInUsd, 2)}</span>
    )
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    className: 'text-center',
    Cell: BalanceMenuAction,
  },
]