import { NetworkDto, OrderQueryStatus, OrderResponse } from '@nestcoinco/onboard-api-gateway-api-client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { NavigateFunction } from 'react-router-dom';
import { toCommaValue } from '../lib';
import { enumToReadable } from '../lib/enumFormatter';
dayjs.extend(relativeTime);
export const orderColumn = (networks: NetworkDto[]) => [
  {
    Header: 'Order ID',
    accessor: 'cexOrderId',
    className: 'text-left',
  },
  {
    Header: 'Customer',
    accessor: 'customerName',
    className: 'text-left',
  },
  {
    Header: 'Merchant',
    accessor: 'offer.partnerDisplayName',
    className: 'text-left',
    Cell: ({ row }: any) => <span>{row.original.offer?.partnerDisplayName ?? 'n/a'}</span>,
  },
  {
    Header: 'Network',
    accessor: 'offer.networkId',
    className: 'text-left',
    Cell: ({ row }: any) => {
      const networkId = row?.original?.offer?.networkId;
      const network = networks?.find((network) => network.networkId === networkId);
      return <div>{network?.name}</div>;
    },
  },
  {
    Header: 'Transaction Type',
    accessor: 'offer.transactionType',
    className: 'text-left',
    Cell: ({ row }: any) => <span>{row.original.offer?.transactionType ?? 'n/a'}</span>,
  },
  {
    Header: 'Fiat Value',
    accessor: 'offer.fiatAmount',
    className: 'text-left',
    Cell: ({ row }: any) => (
      <span>{`${toCommaValue(row.original?.offer?.fiatAmount, 2)} ${
        row.original?.offer?.fiatSymbol
      }`}</span>
    ),
  },
  {
    Header: 'Asset Value',
    accessor: 'offer.tokenAmount',
    className: 'text-left',
    Cell: ({ row }: any) => (
      <span>{`${toCommaValue(row.original?.offer?.tokenAmount, 8)} ${
        row.original?.offer?.token
      }`}</span>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-left',
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    className: 'text-left',
    Cell: ({ row }: any) => <span>{dayjs().to(dayjs(row.original.createdAt))}</span>,
  },
];

export const opnOrderColumn = (navigate: NavigateFunction) => [
  {
    Header: 'Time',
    accessor: 'createdAt',
    className: 'text-left',
    Cell: ({ row }: any) => <span>{dayjs().to(dayjs(row.original.createdAt))}</span>,
  },
  {
    Header: 'Type',
    accessor: 'type',
    className: 'text-left',
    Cell: ({ row }: any) => <span className={`${row.original.type === 'SELL' ? 'text-[red]' : 'text-[green]'}`}>{row.original.type}</span>,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    className: 'text-left',
    Cell: ({ row }: any) => <span>{row.original.amount ?? '0'}</span>,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-left',
    Cell: ({ row }: any) => {
      const classNames: Record<OrderQueryStatus, string> = {
        ONGOING: 'text-[orange]',
        FAILED: 'text-[red]',
        COMPLETED: 'text-[green]',
      }
      const className = classNames[row.original.status as OrderQueryStatus]
      return <span className={className}>{enumToReadable(row.original.status)}</span>;
    },
  },
  {
    Header: 'Rate',
    accessor: 'rate',
    className: 'text-left',
    Cell: ({ row }: any) => <span>{toCommaValue(row.original.rate)}</span>,
  },
  {
    Header: 'Local Currency',
    accessor: 'payoutCurrency',
    className: 'text-left',
    Cell: ({ row }: any) => (
      <span>{row.original.payoutCurrency}</span>
    ),
  },
  {
    Header: 'Local Amount',
    accessor: '',
    className: 'text-left',
    Cell: ({ row }: any) => (
      <span>{toCommaValue(row.original?.amount * row.original?.rate)}</span>
    ),
  },
  {
    Header: 'Method',
    accessor: 'paymentChannel',
    className: 'text-left',
    Cell: ({ row }: any) => { return <span>{enumToReadable(row.original.paymentChannel)}</span>}
  },
  {
    Header: 'Action',
    accessor: 'action',
    className: 'text-left',
    Cell: ({ row }: any) => { return <span onClick={() => { navigate(`/exchanges/${row.original.reference}`) }} className="cursor-pointer text-[blue] underline-offset-3">View</span>}
  },
];
