import React, { PropsWithChildren } from 'react';

interface ITabContentItem {
  id: string;
  controller: string;
  showAsDefault?: boolean;
}

const TabContentItem: React.FC<PropsWithChildren<ITabContentItem>> = ({
  id,
  controller,
  showAsDefault,
  children,
}) => (
  <div
    id={id}
    role='tabpanel'
    className={`tab-pane fade ${showAsDefault ? 'show active' : ''}`}
    aria-labelledby={controller}>
    {children}
  </div>
);

export default TabContentItem;
