import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ArrowBackOutline } from "react-ionicons";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card } from "../../../components";
import PageWrapper from "../../../components/PageWrapper";
import useWithdrawal from "../../../hooks/useWithdrawal";
import { getPercentageValue } from "../../../lib/percentValue";
import {
  formatQuoteMessage,
  toCryptoAmount,
  toFiatAmount,
} from "../../../lib/withdrawal";
import { usdToNgnConvertSchema } from "../../../schemas/withdrawal";

const ConvertFiat = () => {
  const {
    opnQuotedRate,
    setOpnQuotedRate,
    fetchRate,
    generateQuote,
    account,
    isFetching,
    getReadablePaymentChannels,
  } = useWithdrawal();

  const location = useLocation();

  const navigate = useNavigate();
  const initialValues = {
    usd: 0,
    ngn: 0,
  };
  const [isLoading, setIsLoading] = useState(false);
  const [rateIsSet, setRateIsSet] = useState(false);
  const [rate, setRate] = useState(0);

  const paymentMethod = (location.state as any).paymentMethod;

  useEffect(() => {
    setOpnQuotedRate(undefined);
    fetchRate().then((data) => {
      setRate(data?.price!);
    });
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: usdToNgnConvertSchema(account?.availableBalance!),
    onSubmit: async (values) => {},
  });

  const handleGenerateQuote = () => {
    setIsLoading(true);
    const { usd } = formik.values;
    generateQuote(usd, "NGN", paymentMethod)
      .then((data) => {
        if (data?.isQuote) {
          // set NGN
          setRateIsSet(true);
          formik.setFieldValue(
            "ngn",
            Math.floor(usd * data?.rate! * 100) / 100
          );
        } else if (data?.isFallback) {
          setRateIsSet(true);
          formik.setFieldValue(
            "usd",
            Math.min(data.maximumAmount!, account?.availableBalance!)
          );
          formik.setFieldValue(
            "ngn",
            Math.floor(
              Math.min(data.maximumAmount!, account?.availableBalance!) *
                data?.rate! *
                100
            ) / 100
          );
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = async () => {
    const errors = await formik.validateForm();
    if (!formik.isValid) {
      formik.setErrors(errors);
      return;
    }
    const { usd, ngn } = formik.values;

    navigate("/withdrawal/onboard-fast/complete", {
      state: {
        ngn,
        usd,
        opnQuotedRate,
      },
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <PageWrapper title="Complete Withdrawal">
      <p className="mt-4 flex cursor-pointer" onClick={handleBack}>
        <ArrowBackOutline /> Back{" "}
      </p>
      <div className="flex justify-center w-full">
        <Card
          title="Confirm withdrawal"
          headingClassNames="mb-8 text-center"
          classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
          children={
            <>
              <p className="text-center text-sm mb-6">
                Enter amount to withdraw{" "}
                {paymentMethod
                  ? "for " +
                    getReadablePaymentChannels(
                      (location.state as any).paymentMethod
                    )
                  : ""}
              </p>
              <div className="w-full">
                <div className="flex border-2 rounded items-center">
                  <input
                    name="usd"
                    min={0}
                    className={
                      "appearance-none block w-full text-gray-700  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    }
                    type="number"
                    value={formik.values.usd}
                    onChange={async (e) => {
                      formik.handleChange(e);
                      await formik.validateField("usd");
                      formik.setFieldValue(
                        "ngn",
                        toFiatAmount(e.target.value, rate)
                      );
                      setRateIsSet(false);
                    }}
                  />
                  <span className="text-gray-500 pr-4">(USD)</span>
                </div>
                <p className="text-red-500 text-sm ml-2 mt-2 italic">
                  {formik.errors.usd}
                </p>
                <div className="flex justify-end gap-4 text-sm py-2">
                  <span
                    className="cursor-pointer"
                    onClick={(e) => {
                      formik.setFieldValue(
                        "usd",
                        getPercentageValue(0.1, account?.availableBalance!),
                        true
                      );
                      formik.setFieldValue(
                        "ngn",
                        Math.floor(
                          getPercentageValue(0.1, account?.availableBalance!) *
                            rate
                        )
                      );
                      setRateIsSet(false);
                    }}
                  >
                    [10%]
                  </span>{" "}
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      formik.setFieldValue(
                        "usd",
                        getPercentageValue(0.25, account?.availableBalance!),
                        true
                      );
                      formik.setFieldValue(
                        "ngn",
                        Math.floor(
                          getPercentageValue(0.25, account?.availableBalance!) *
                            rate
                        )
                      );
                      setRateIsSet(false);
                    }}
                  >
                    [25%]
                  </span>{" "}
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      formik.setFieldValue(
                        "usd",
                        getPercentageValue(0.5, account?.availableBalance!),
                        true
                      );
                      formik.setFieldValue(
                        "ngn",
                        Math.floor(
                          getPercentageValue(0.5, account?.availableBalance!) *
                            rate
                        )
                      );
                      setRateIsSet(false);
                    }}
                  >
                    [50%]
                  </span>
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      formik.setFieldValue(
                        "usd",
                        account?.availableBalance!,
                        true
                      );
                      formik.setFieldValue(
                        "ngn",
                        Math.floor(account?.availableBalance! * rate)
                      );
                      setRateIsSet(false);
                    }}
                  >
                    [MAX]
                  </span>
                </div>
                <div className="p-3" />

                <div className="flex border-2 rounded items-center">
                  <input
                    name="ngn"
                    min={0}
                    className={
                      "appearance-none block w-full text-gray-700  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    }
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "usd",
                        toCryptoAmount(e.target.value, rate)
                      );
                      setRateIsSet(false);
                    }}
                    type="number"
                    value={formik.values.ngn}
                  />
                  <span className="text-gray-500 pr-4">(NGN)</span>
                </div>
                <p>{formik.errors.ngn}</p>

                <div className="p-3" />

                {rate ? (
                  <p className="text-center text-gray-500">
                    <span>Rate:</span> NGN {rate}/ USD
                  </p>
                ) : null}
                <p
                  className={`text-center mt-2 text-[14px] ${
                    opnQuotedRate && !opnQuotedRate?.isQuote
                      ? "text-[#f99241]"
                      : ""
                  }`}
                >
                  {formatQuoteMessage(opnQuotedRate)}
                </p>

                <div className="p-3" />
                <Button
                  loading={isLoading}
                  className="w-full btn-primary"
                  disabled={!formik.isValid || !formik.values.usd}
                  text={rateIsSet ? "Continue with this quote" : "Get quote"}
                  onClick={() => {
                    rateIsSet ? handleSubmit() : handleGenerateQuote();
                  }}
                />
              </div>
            </>
          }
        />
      </div>
    </PageWrapper>
  );
};

export default ConvertFiat;
