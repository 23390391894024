import * as Yup from "yup";
import { validateAssetAddress } from "../lib/validator";

export const externalWithdrawalSchema = (
  tokenType: string,
  accountBalance: number
) =>
  Yup.object().shape({
    address: Yup.string()
      .required()
      .test(`validAddress`, "Invalid address", function (value) {
        const { path, createError } = this;

        return (
          validateAssetAddress(tokenType, value!) ||
          createError({ path, message: "Invalid address" })
        );
      }),
    amount: Yup.number()
      .test(`balanceAvailable`, "Insufficient Balance", function (value) {
        const { path, createError } = this;

        return (
          Number(value) <= accountBalance ||
          createError({ path, message: "Insufficient Balance" })
        );
      })
      .required(),
  });

export const completeExternalWithdrawalSchema = Yup.object().shape({
  otp: Yup.string().required(),
});

export const usdToNgnConvertSchema = (accountBalance: number) =>
  Yup.object().shape({
    usd: Yup.number()
      .test(
        "InsufficientBalance",
        "Insufficient Balance for this amount",
        function (value) {
          const { path, createError } = this as any;

          if (Number(value) < 0) {
            return createError({
              path,
              message: "Amount entered cannot be less than zero.",
            });
          }

          return (
            Number(value) <= accountBalance ||
            createError({
              path,
              message: "Insufficient Balance for this amount.",
            })
          );
        }
      )
      .required("please enter amount you want to withdraw"),
    ngn: Yup.number(),
  });

export const p2pwalletRecipientSchema = Yup.object().shape({
  accountId: Yup.string().min(2).required(),
  email: Yup.string().email().required(),
  firstName: Yup.string().min(2).required(),
  lastName: Yup.string().min(2).required(),
  phone: Yup.string().min(10).required(),
});
