import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPartiallyAuthenticatedUserStatusDetail } from "../../api/auth";
import useAuth from "../useAuth";

const useHasSetup2fa = () => {
  const { authToken, storageLoaded } = useAuth();
  const [loading, setLoading] = useState(true);
  const [hasSetup2fa, setHasSetup2fa] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!storageLoaded) return;
    const check2faStatus = async (): Promise<boolean> => {
      try {
        const partialUser = await getPartiallyAuthenticatedUserStatusDetail(
          authToken!
        );
        return partialUser.isTotpSetup;
      } catch {
        return false;
      }
    };

    check2faStatus()
      .then(setHasSetup2fa)
      .finally(() => setLoading(false));
  }, [storageLoaded, authToken, navigate]);

  return { loading, hasSetup2fa };
};

export default useHasSetup2fa;
