import React, { ReactNode } from "react";
import { CopyOutline } from "react-ionicons";
import { useToasts } from "react-toast-notifications";
import Button from "../Button";

interface ICopier {
  text: string;
  iconColor?: any;
  children?: ReactNode;
  className?: string;
}

const Copier: React.FC<ICopier> = ({
  text,
  iconColor,
  children,
  className,
}) => {
  const { addToast } = useToasts();
  const handleCopy = () => {
    navigator.clipboard.writeText(text ?? "");
    addToast("Copied to clipboard", { appearance: "success" });
  };

  if (children) {
    return (
      <Button
        onClick={() => handleCopy()}
        className={`flex gap-1 items-center ${className}`}
      >
        {children}{" "}
        <CopyOutline
          color={iconColor ?? "inherit"}
          cssClasses="cursor-pointer"
        />
      </Button>
    );
  }
  return (
    <CopyOutline
      color={iconColor ?? "inherit"}
      onClick={() => handleCopy()}
      cssClasses="cursor-pointer"
    />
  );
};

export default Copier;
