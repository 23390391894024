import { useContext } from "react";
import { ConfigContext } from "../../providers/ConfigProvider";

const useConfig = () => {
  const configContext = useContext(ConfigContext);

  if (!configContext)
    throw new Error(
      "PROVIDER_ERROR: useConfig can only be used within ConfigContext"
    );

  return configContext;
};

export default useConfig;
