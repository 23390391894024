import React from "react";
import { NavLink } from "react-router-dom";
import { ArrowUp, ArrowDown } from "react-ionicons";
import { toCommaValue } from "../../../lib";

interface WalletCardProps {
  currency: string;
  balance: any;
}

const WalletCard = ({ currency, balance }: WalletCardProps) => {
  return (
    <div className="rounded-[10px] basis-[30.75rem] bg-[#000D20] text-white p-[36px] px-[25px]">
      <div>
        <div className="mb-5">
          <p className="font-semibold flex items-baseline gap-2">
            <span className="text-[1.875rem]">
              {toCommaValue(balance.availableBalance || 0)}
            </span>
            <span className="text-sm">{currency}</span>
          </p>
          <p className="text-xs">Available balance</p>
        </div>
        <div></div>
      </div>
      <div className="flex justify-end gap-3">
        <NavLink to="/deposit" className="flex gap-1 items-center">
          <ArrowDown color="green" />
          Deposit
        </NavLink>
        <NavLink to="/withdrawal" className="flex gap-1 items-center">
          <ArrowUp color="#1876d2" />
          Withdraw
        </NavLink>
      </div>
    </div>
  );
};

export default WalletCard;
