import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { TabContent, TabContentItem, TabNavList, TabNavListItm } from '../../components/Tabs';
import URL from './components/URL';
import WebhookSecret from './components/WebhookSecret';

const Settings = () => {
  const [params] = useSearchParams();
  const tab: 'url' | 'secret' | null = params.get('tab') as any;
  const activeTab = useMemo(() => tab ?? 'url', [tab]);

  return (
    <PageWrapper title='Settings'>
      <div className='mt-7'>
        <TabNavList id='cex-settings'>
          <TabNavListItm
            title='URL Configs'
            id='url-configs'
            controls='url-configs-tab'
            showAsDefault={activeTab === 'url'}
            tabParam='url'
          />
          <TabNavListItm
            title='Webhook Secret'
            id='api-keys'
            controls='api-keys-tab'
            showAsDefault={activeTab === 'secret'}
            tabParam='secret'
          />
        </TabNavList>
        <TabContent id='cex-settings-content'>
          <TabContentItem
            id='url-configs-tab'
            controller='url-configs'
            showAsDefault={activeTab === 'url'}>
            <URL />
          </TabContentItem>
          <TabContentItem
            id='api-keys-tab'
            controller='api-keys'
            showAsDefault={activeTab === 'secret'}>
            <WebhookSecret />
          </TabContentItem>
        </TabContent>
      </div>
    </PageWrapper>
  );
};
export default Settings;
