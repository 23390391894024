import { useContext } from "react";
import { WithdrawalContext } from "../../providers/WithdrawalProvider";

const useWithdrawal = () => {
  const withdrawalContext = useContext(WithdrawalContext);

  if (!withdrawalContext)
    throw new Error(
      "PROVIDER_ERROR: useWithdrawal can only be used within WithdrawalContext"
    );

  return withdrawalContext;
};

export default useWithdrawal;
