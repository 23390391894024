import ApiClient, { getTokenHeader } from './client';
import {
  CexConfigDto,
  GenerateWebhookSecretCommand,
  WebhookSecretDto,
} from '@nestcoinco/onboard-api-gateway-api-client';

export const getCexConfigs = async (token: string): Promise<CexConfigDto | undefined> => {
  if (!token) return;
  const { data } = await ApiClient.exchange.cex.queryCexConfigs(getTokenHeader(token));
  return data;
};

export const generateOrResetWebhookSecret = async (
  token: string,
  postData: GenerateWebhookSecretCommand
): Promise<WebhookSecretDto | undefined> => {
  if (!token) return;
  const { data } = await ApiClient.exchange.cex.generateOrResetWebhookSecret(
    postData,
    getTokenHeader(token)
  );
  return data;
};

export const viewWebhookSecret = async (token: string, totp: string): Promise<WebhookSecretDto | undefined> => {
  if (!token) return;
  const { data } = await ApiClient.exchange.cex.viewWebhookSecret(totp, getTokenHeader(token));
  return data;
};
