import { AccountTransactionTransactionTypeEnum as AccountTransactionType } from "@nestcoinco/onboard-api-gateway-api-client";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { NavLink } from "react-router-dom";
import { Button } from "../components";
import { toCommaValue } from "../lib";
import { getValueFromMetadata } from "../lib/transactions";
import { toPositiveValue } from "../lib/toPositiveValue";
dayjs.extend(relativeTime);

export const transactionColumn = () => [
  {
    Header: "Time",
    accessor: "createdAt",
    className: "text-center",
    Cell: ({ row }: any) => (
      <span>{dayjs().to(dayjs(row.original.createdAt * 1000))}</span>
    ),
  },
  {
    Header: "Summary",
    accessor: "notes",
    className: "text-left",
  },
  {
    Header: "Txn Type",
    className: "text-left",
    Cell: ({ row }: any) => {
      return <span>{row?.original?.type.split("_")[1]}</span>;
    },
  },
  {
    Header: "Amount",
    accessor: "amount",
    className: "text-center",
    Cell: ({ row }: any) => {
      const amount = row?.original.feeInclusive
        ? row?.original?.amount! + row?.original.feeAmount!
        : row?.original?.amount;
      return <span>{toPositiveValue(toCommaValue(amount))}</span>;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    className: "text-center",
    Cell: ({ row }: any) => {
      const status = row?.original?.status;
      return (
        <span>
          {status ? status?.charAt(0).toUpperCase() + status.slice(1) : ""}
        </span>
      );
    },
  },
  {
    Header: "Rate",
    accessor: "",
    className: "text-center",
    Cell: ({ row }: any) => {
      const rate = getValueFromMetadata("rate", row?.original?.metadata!);
      return <span>{rate}</span>;
    },
  },
  {
    Header: "Local Currency",
    accessor: "currency",
    className: "text-center",
    Cell: ({ row }: any) => {
      const localCurrency =
        getValueFromMetadata("localCurrency", row?.original?.metadata!) || "-";
      return <span>{localCurrency}</span>;
    },
  },
  {
    Header: "Local Amount",
    accessor: "localAmount",
    className: "text-center",
    Cell: ({ row }: any) => {
      const rate = getValueFromMetadata("rate", row?.original?.metadata!);
      const amount = row?.original.feeInclusive
        ? row?.original?.amount! + row?.original.feeAmount!
        : row?.original?.amount;

      return (
        <span>{rate ? toPositiveValue(Number(rate!) * amount) : "-"}</span>
      );
    },
  },
  {
    Header: "actions",
    accessor: "",
    Cell: ({ row }: any) => {
      return (
        <NavLink
          className="bg-blue-700 hover:bg-blue-600 text-white cursor-pointer px-5 py-4 rounded-[6px] text-base font-medium leading-none text-center"
          to={`/wallet/transactions/${row?.original?.reference.replace(
            "OPN-",
            ""
          )}`}
          state={{ referenceId: row?.original?.reference }}
        >
          view
        </NavLink>
      );
    },
  },
];

const transactionTypeMap = {
  [AccountTransactionType.CRYPTO_WITHDRAWAL]: "Crypto Withdrawal",
  [AccountTransactionType.CRYPTO_DEPOSIT]: "Crypto Deposit",
  [AccountTransactionType.FIAT_WITHDRAWAL]: "Fiat Withdrawal",
  [AccountTransactionType.FIAT_DEPOSIT]: "Fiat Deposit",
};

export const typeFilterItems = [
  {
    text: "All",
    filter: null,
  },
  {
    filter: AccountTransactionType.CRYPTO_WITHDRAWAL,
    text: transactionTypeMap[AccountTransactionType.CRYPTO_WITHDRAWAL],
  },
  {
    filter: AccountTransactionType.CRYPTO_DEPOSIT,
    text: transactionTypeMap[AccountTransactionType.CRYPTO_DEPOSIT],
  },
];

export const DEBIT = "_DEBIT";
