import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TableActionMenu } from '../../../../../components';

const MenuActionCol = ({ row }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <TableActionMenu row={row} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Link
        onClick={() => setIsOpen(false)}
        to={`/liquidity-pools/${row.original.address}?networkId=${row.original.networkId}`}
        className='block p-2 text-xs rounded text-slate-600 hover:font-medium hover:bg-blue-100 hover:text-blue-700'>
        View
      </Link>
      <a
        onClick={() => setIsOpen(false)}
        href={`${row.original.explorerUrl}/address/${row.original.address}`}
        target='_blank'
        rel='noreferrer'
        className='block p-2 text-xs rounded text-slate-600 hover:font-medium hover:bg-blue-100 hover:text-blue-700'>
        View on explorer
      </a>
    </TableActionMenu>
  );
};

export default MenuActionCol;
