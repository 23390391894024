import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MiddleWareExecutor } from "../components";
import { EmptyLayout } from "../components/Layouts";
import { NotFoundPage } from "../pages";
import { RouteMapItem } from "../typings/interfaces/route";
import routeMap from "./routeMap";

const parseRoute = (routeKey: string, routeItem: RouteMapItem) => {
  // parse route
  const parsedRoute = { ...routeItem };

  const Layout: any = parsedRoute.Layout || EmptyLayout;
  parsedRoute.index = parsedRoute.index ?? false;

  // check if has child routes
  const childRoutes = routeItem.childRoutes;
  if (childRoutes) {
    parsedRoute.path = parsedRoute.path + "/*";

    const ChildRoute = () => (
      <Routes>
        {Object.keys(childRoutes).map((key) =>
          parseRoute(`${routeKey}/${key}`, childRoutes[key])
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );

    return (
      <Route
        element={
          <MiddleWareExecutor middlewares={parsedRoute.middlewares || []}>
            <Layout />
          </MiddleWareExecutor>
        }
        key={routeKey}
      >
        <Route path={parsedRoute.path} element={<ChildRoute />}></Route>
      </Route>
    );
  }

  return (
    <Route
      element={
        <MiddleWareExecutor middlewares={parsedRoute.middlewares || []}>
          <Layout />
        </MiddleWareExecutor>
      }
      key={routeKey}
    >
      <Route
        path={parsedRoute.path}
        element={parsedRoute.Element && <parsedRoute.Element />}
        index={parsedRoute.index}
      />
    </Route>
  );
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {Object.keys(routeMap).map((routeKey) => {
          return parseRoute(routeKey, routeMap[routeKey]);
        })}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
