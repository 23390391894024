import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../hooks";

const useCheckResetRequest = () => {
  const { request } = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!request) return navigate("/auth/login");
  }, [request, navigate]);

  return true;
};

export default useCheckResetRequest;
