import { FC } from "react";
import { SearchIcon } from "../../../Icons";

interface SearchProps {
  value: string;
  setValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search: FC<SearchProps> = ({ value, setValue }) => {
  return (
    <div className="flex items-center border rounded border-slate-400">
      <input
        type="text"
        name="search"
        value={value}
        className="max-w-[236px] w-full rounded py-[10px] px-4 border-none outline-none placeholder:text-slate-600 placeholder:text-sm placeholder:leading-[14px] leading-[14px]"
        placeholder="Search"
        onChange={setValue}
      />

      <SearchIcon />
    </div>
  );
};

export default Search;
