import {
  Account,
  AccountBalance,
  AssetBlockchainTokens,
  CreateAccountRequest,
  CustomerAccountList,
  FundingAddress,
} from "@nestcoinco/onboard-api-gateway-api-client";
import ApiClient, { getTokenHeader } from "./client";

export const getCustomerAccounts = async (
  authToken: string
): Promise<CustomerAccountList> => {
  const { data } = await ApiClient.accounts.fetchCustomerAccounts(
    getTokenHeader(authToken)
  );

  return data;
};

export const createAccount = async (
  authToken: string,
  accountRequest: CreateAccountRequest = { currency: "USDX" }
): Promise<Account> => {
  const { data } = await ApiClient.accounts.createCustomerAccount(
    accountRequest,
    getTokenHeader(authToken)
  );

  return data;
};

export const getAccountBlance = async (
  accountId: string
): Promise<AccountBalance> => {
  const { data } = await ApiClient.accounts.getAccountBalance(accountId);

  return data;
};

export const getNetworkTokens = async (
  authToken: string,
  asset: string = "USDX"
): Promise<AssetBlockchainTokens> => {
  const { data } = await ApiClient.accounts.getAssetNetworkTokensLedger(
    asset,
    getTokenHeader(authToken)
  );

  return data;
};

export const getFundingAddress = async (
  authToken: string,
  accountId: string,
  networkCode: string
): Promise<FundingAddress> => {
  const { data } = await ApiClient.accounts.getFundingAddressForToken(
    networkCode,
    accountId,
    getTokenHeader(authToken)
  );

  return data;
};

export const createFundingAddress = async (
  authToken: string,
  accountId: string,
  networkCode: string
): Promise<FundingAddress> => {
  const { data } = await ApiClient.accounts.createFundingAddressForToken(
    networkCode,
    accountId,
    getTokenHeader(authToken)
  );

  return data;
};
