import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";
import { useWindowWidth } from "@react-hook/window-size";
import { screenLg } from "../../../constants/screen";
import classnames from "classnames";

const Main = () => {
  const [sidebarCollapse, setSideBarCollapse] = useState(true);
  const width = useWindowWidth();

  useEffect(() => {
    if (width < screenLg) {
      setSideBarCollapse(true);
    } else {
      setSideBarCollapse(false);
    }
  }, [width]);

  return (
    <>
      <Sidebar
        collapsed={sidebarCollapse}
        toggle={() => setSideBarCollapse((v) => !v)}
      />
      <div
        className={classnames([
          "w-full transition-all duration-500",
          {
            "ml-[260px]": !(sidebarCollapse || width <= screenLg),
          },
        ])}
      >
        <Navbar
          collapsed={sidebarCollapse}
          toggle={() => setSideBarCollapse((v) => !v)}
        />

        <Outlet />
      </div>
    </>
  );
};

export default Main;
