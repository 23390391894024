import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks";
import { AuthType } from "../providers/AuthProvider";

const useAuthenticated = () => {
  const { authType, authToken, verified, storageLoaded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!storageLoaded) return;
    const authenticated = verified && authType === AuthType.FULL && authToken;

    if (authenticated) {
      return navigate("/");
    }
  }, [authToken, authType, verified, storageLoaded, navigate]);

  return true;
};

export default useAuthenticated;
