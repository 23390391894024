import {
  CRYPTO_DECIMAL_PLACES,
  FIAT_DECIMAL_PLACES,
} from "../constants/withdrawal";

export const formatQuoteMessage = (opnQuotedRate: any) => {
  if (opnQuotedRate?.isFallback) {
    return `We were not able to get you a matching quote but try an amount within the range ${opnQuotedRate.minimumAmount} - ${opnQuotedRate.maximumAmount} at  NGN ${opnQuotedRate.rate}/ USD`;
  } else if (opnQuotedRate?.isQuote) {
    return `Quoted rate: NGN ${opnQuotedRate.rate}/ USD`;
  } else if (opnQuotedRate) {
    return "We couldn't find a quote for you, try a lower amount";
  } else {
    return "";
  }
};

export const extractUserName = (
  name: string,
  type: "lastName" | "firstName"
) => {
  switch (type) {
    case "firstName":
      return name.split(" ")[0];
    case "lastName":
      return name.split(" ")[1];
    default:
      return name.split(" ")[0];
  }
};

export const calculatewithdrawalFee = (
  withdrawalFee: number,
  decimals: number
) => {
  return (withdrawalFee! / 10 ** decimals).toFixed(decimals + 1);
};

export const toFiatAmount = (tokenAmount: number | string, rate: number) => {
  const value = rate ? +tokenAmount * rate : 0;

  return value && !isNaN(value)
    ? String(Number(value).toFixed(FIAT_DECIMAL_PLACES))
    : "0";
};

export const toCryptoAmount = (fiatAmount: number | string, rate: number) => {
  const value = rate ? +fiatAmount / rate : 0;
  const [, fraction] = String(value).split(".");

  if (fraction && fraction.length > CRYPTO_DECIMAL_PLACES)
    return Number(value).toFixed(CRYPTO_DECIMAL_PLACES);

  return value && !isNaN(value) ? value.toString() : "0";
};
