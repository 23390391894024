interface IError {
  message: string;
  data: Record<string, string[]> | undefined;
}

export const getErrorMessage = (e: any): IError => {
  const message = e.response?.data?.message || e.message;
  const data = e.response?.data?.data ?? null;
  return { message, data }
};
