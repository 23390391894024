import Filters from "./components/Filters";
import Pagination from "./components/Pagination";
import TableBody from "./components/TableBody";
import TableHead from "./components/TableHead";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  Column,
} from "react-table";
import { FC, ReactNode, useMemo } from "react";
import Loader from "../Loader";

interface TableProps {
  data: Record<string, any>[];
  columns: Column<any>[];
  noResultMessage?: string | ReactNode;
  title?: string;
  loading?: boolean;
  showFilter?: boolean;
}

const Table: FC<TableProps> = ({
  showFilter,
  columns,
  data,
  loading,
  noResultMessage,
  title,
}) => {
  const {
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize, globalFilter },
    pageCount,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  ) as any;

  const tableBodyProps = useMemo(
    () => getTableBodyProps(),
    [getTableBodyProps]
  );

  return (
    <div className="flex flex-col bg-white rounded shadow">
      <div className="flex justify-between items-center">
        <h2 className="p-5 text-lg font-bold">{title ?? ""}</h2>
        {showFilter ? (
          <Filters
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        ) : null}
      </div>

      <div className="overflow-x-auto overflow-y-hidden">
        <table className="w-full whitespace-nowrap" {...getTableProps()}>
          <TableHead headerGroups={headerGroups} />
          {loading ? (
            <tbody>
              <tr>
                <td colSpan={columns.length}>
                  <div className="w-full flex min-h-[100px]  justify-center items-center">
                    <Loader color="blue-600" />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : !page.length && noResultMessage ? (
            <tbody>
              <tr>
                <td colSpan={columns.length}>
                  <div className="w-full flex min-h-[100px]  justify-center items-center">
                    {noResultMessage}
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <TableBody
              tableBodyProps={tableBodyProps}
              rows={page}
              prepareRow={prepareRow}
            />
          )}
        </table>
      </div>
      <Pagination
        limit={pageSize}
        neighbours={2}
        total={pageCount}
        currentPage={pageIndex + 1}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
      />
    </div>
  );
};

export default Table;
