import {
  ConfirmTransferRequest,
  CreateBeneficiaryRequest,
  CryptoTransferRequest,
  NaijaAccountValidationRequest,
  PayOutRequest,
} from "@nestcoinco/onboard-api-gateway-api-client";
import ApiClient, { getTokenHeader } from "./client";

export const getAssets = async (token: string) => {
  const { data } = await ApiClient.ledger.account.getSupportedAssets(
    getTokenHeader(token)
  );
  return data.assets;
};

export const getAssetNetworkTokens = async (
  assetCode: string,
  token: string
) => {
  const { data } = await ApiClient.ledger.account.getAssetNetworkTokensLedger(
    assetCode,
    getTokenHeader(token)
  );
  return data.tokens;
};

export const initiateCryptoTransfer = async (
  payload: CryptoTransferRequest,
  token: string
) => {
  const { data } = await ApiClient.ledger.transfer.cryptoTransfer(
    payload,
    getTokenHeader(token)
  );
  return data;
};

export const confirmCryptoTransfer = async (
  txRef: string,
  payload: ConfirmTransferRequest,
  token: string
) => {
  const { data } = await ApiClient.ledger.transfer.confirmCryptoTransfer(
    txRef,
    payload,
    getTokenHeader(token)
  );
  return data;
};

export const getMyLedgerAccounts = async (token: string) => {
  const { data } = await ApiClient.ledger.account.fetchCustomerAccounts(
    getTokenHeader(token)
  );
  return data.content;
};

export const getSavedBeneficiaries = async (
  accountId: string,
  token: string
) => {
  const { data } = await ApiClient.ledger.account.getSavedBeneficiaries(
    accountId,
    getTokenHeader(token)
  );
  return data.content;
};
export const saveBeneficiary = async (
  payload: CreateBeneficiaryRequest,
  token: string
) => {
  const { data } = await ApiClient.ledger.account.saveBeneficiary(
    payload,
    getTokenHeader(token)
  );
  return data;
};

export const requestPayout = async (
  transferRequest: PayOutRequest,
  token: string
) => {
  const { data } = await ApiClient.ledger.payments.createPayOutRequest(
    transferRequest,
    getTokenHeader(token)
  );
  return data;
};

export const confirmPayout = async (
  transactionRef: string,
  confirmTransferRequest: ConfirmTransferRequest,
  token: string
) => {
  const { data } = await ApiClient.ledger.payments.confirmPayout(
    transactionRef,
    confirmTransferRequest,
    getTokenHeader(token)
  );
  return data;
};

export const getNigerianBanks = async (token: string) => {
  const { data } = await ApiClient.ledger.payments.fetchNigerianBanks(
    getTokenHeader(token)
  );
  return data.content;
};

export const verifyBankAccount = async (
  naijaAccountValidationRequest: NaijaAccountValidationRequest,
  token: string
) => {
  const { data } = await ApiClient.ledger.payments.validateNaijaAccount(
    naijaAccountValidationRequest,
    getTokenHeader(token)
  );
  return data;
};
