import { useContext } from "react";
import { UrlContext } from "../../providers/UrlProvider";

const useUrls = () => {
  const urlContext = useContext(UrlContext);

  if (!urlContext)
    throw new Error(
      "PROVIDER_ERROR: useUrls can only be used within UrlContext"
    );

  return urlContext;
};

export default useUrls;
