import { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";

const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext)
    throw new Error(
      "PROVIDER_ERROR: useAuth can only be used within AuthContext"
    );

  return authContext;
};

export default useAuth;
