import { Outlet } from "react-router-dom";
import { OnboardLogo } from "../../Icons";

const AuthLayout = () => {
  return (
    <div className="w-[1224px] m-auto flex h-[100vh] flex-col px-6">
      <div className="pt-16 mb-10">
        <a href="/" className="w-[100px] h-[50px] block">
          <OnboardLogo />
        </a>
      </div>
      <div className="w-full flex justify-center">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
