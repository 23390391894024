import {
  Account,
  Beneficiary,
  ConfirmTransferResponse,
  CreateBeneficiaryRequest,
  CreateOrderRequest,
  NaijaAccountValidationResponse,
  OrderType,
  PayoutProvider,
  PayOutRequest,
  PayoutsSvcProviderPaymentChannel,
  ProviderPaymentChannel,
  QuoteDto,
  RateDto,
  StatusDto,
  TokenDetails,
  TokenNetwork,
  TransferResponse,
  User2FAMethod,
} from "@nestcoinco/onboard-api-gateway-api-client";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useToasts } from "react-toast-notifications";
import { getErrorMessage } from "../../api/error-handler";
import {
  confirmCryptoTransfer,
  confirmPayout,
  getAssetNetworkTokens,
  getMyLedgerAccounts,
  getNigerianBanks,
  getSavedBeneficiaries,
  initiateCryptoTransfer,
  requestPayout,
  saveBeneficiary,
  verifyBankAccount,
} from "../../api/ledger";
import { createClientOrder, getQuote, getReferenceRate } from "../../api/order";
import { getProviders } from "../../api/payouts";
import { DropdownItem } from "../../components/Dropdown";
import { useAuth } from "../../hooks";
import { ContextActionReturnType } from "../../typings/interfaces/context";

export type QuotedRateType = QuoteDto & {
  isFallback?: boolean;
  isQuote?: boolean;
};

interface WithdrawalContextProps {
  loading: boolean;
  isFetching: boolean;
  tokens?: TokenDetails[];
  tokenNetworks?: TokenNetwork[];
  selectedTokenNetwork?: TokenNetwork;
  setSelectedToken: Dispatch<SetStateAction<string>>;
  setOpnQuotedRate: Dispatch<SetStateAction<QuotedRateType | undefined>>;
  setSelectedTokenNetwork: Dispatch<SetStateAction<TokenNetwork | undefined>>;
  selectedToken: string;
  initCryptoTransfer: (
    address: string,
    amount: number
  ) => Promise<ContextActionReturnType | void>;
  otpMessage: string;
  cryptoTransferResponse?: TransferResponse;
  account?: Account;
  USD?: number;
  NGN?: number;
  setOnboardFastWithdrawalAmount: (
    field: string,
    amount: number,
    resetRate?: boolean
  ) => ContextActionReturnType | void;
  opnQuotedRate?: QuotedRateType;
  generateQuote: (
    amount: number,
    payoutCurrency: string,
    paymentChannel: ProviderPaymentChannel
  ) => Promise<QuotedRateType | void>;
  fetchRate: () => Promise<RateDto | void>;
  getReadablePaymentChannels: (
    method: PayoutsSvcProviderPaymentChannel
  ) => string;
  nigerianBanks: DropdownItem[];
  getBanks: () => Promise<ContextActionReturnType | void>;
  savedBeneficiaries: Beneficiary[];
  getBeneficiaries: () => Promise<ContextActionReturnType | void>;
  verifyAccountNumber: (
    accountNumber: string,
    bankCode: string
  ) => Promise<void | NaijaAccountValidationResponse>;
  saveABeneficiary: (beneficiary: CreateBeneficiaryRequest) => Promise<void>;
  initPayout: (
    payoutRequest: PayOutRequest
  ) => Promise<TransferResponse | void>;
  createOrder: (payload: CreateOrderRequest) => Promise<StatusDto | void>;
  selectedMethod?: DropdownItem;
  setSelectedMethod: Dispatch<SetStateAction<DropdownItem | undefined>>;
  selectedProvider?: DropdownItem;
  setSelectedProvider: Dispatch<SetStateAction<DropdownItem | undefined>>;
  confirmPayoutOtp: (
    transactionRef: string,
    code: string
  ) => Promise<ConfirmTransferResponse | void>;
  confirmCryptoTransferOtp: (
    transactionRef: string,
    code: string,
    twoFaMethod: User2FAMethod
  ) => Promise<ConfirmTransferResponse | void>;
  fetchProviders: () => Promise<PayoutProvider[] | void>;
}

export const WithdrawalContext = createContext<WithdrawalContextProps | null>(
  null
);

const WithdrawalProvider = (prop: any) => {
  const { authToken, verified } = useAuth();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [tokens, setTokens] = useState<TokenDetails[] | undefined>([]);
  const [tokenNetworks, setTokenNetworks] = useState<TokenNetwork[]>();
  const [selectedTokenNetwork, setSelectedTokenNetwork] = useState<
    TokenNetwork | undefined
  >();
  const [cryptoTransferResponse, setCryptoTransferResponse] =
    useState<TransferResponse>();
  const [otpMessage, setOtpMessage] = useState<string>("");
  const [selectedToken, setSelectedToken] = useState("");
  const [account, setAccount] = useState<Account>();
  const [opnQuotedRate, setOpnQuotedRate] = useState<QuotedRateType>();
  const [NGN, setNGN] = useState<number>();
  const [USD, setUSD] = useState<number>();
  const [nigerianBanks, setNigerianBanks] = useState<DropdownItem[]>([]);
  const [savedBeneficiaries, setSavedBeneficiaries] = useState<Beneficiary[]>(
    []
  );
  const [selectedMethod, setSelectedMethod] = useState<DropdownItem>();
  const [selectedProvider, setSelectedProvider] = useState<DropdownItem>();

  const handleError = useCallback(
    (e: any) => {
      const error = getErrorMessage(e);
      addToast(error?.message, { appearance: "error" });
    },
    [addToast]
  );
  const getReadableMethods = useCallback((method: User2FAMethod) => {
    const methodMapper = {
      [User2FAMethod.AUTHENTICATOR_APP]: "Authenticator app",
      [User2FAMethod.EMAIL]: "Email",
      [User2FAMethod.SMS]: "SMS",
    };
    return methodMapper[method];
  }, []);

  const getReadablePaymentChannels = useCallback(
    (method: PayoutsSvcProviderPaymentChannel) => {
      const methodMapper = {
        [PayoutsSvcProviderPaymentChannel.BANK_TRANSFER]: "Bank transfer",
        P2P_WALLET: "P2P wallet",
        TOKENIZED_CARD: "Tokenized card",
      };
      return methodMapper[method];
    },
    []
  );

  const getBanks = useCallback(async () => {
    if (!authToken || !verified) {
      return;
    }
    getNigerianBanks(authToken!)
      .then((banks) => {
        const bankList = banks?.map((bank) => {
          return {
            value: bank.code,
            text: bank.bankName,
            icon: "",
          } as DropdownItem;
        });
        setNigerianBanks(bankList!);
      })
      .catch(handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, verified]);

  const getBeneficiaries = useCallback(async () => {
    if (!authToken || !verified) {
      return;
    }
    getSavedBeneficiaries(account?.id!, authToken!)
      .then((data) => setSavedBeneficiaries(data!))
      .catch(handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, authToken, verified]);

  const saveABeneficiary = useCallback(
    async (payload: CreateBeneficiaryRequest) => {
      if (!authToken || !verified) {
        return;
      }
      saveBeneficiary(payload, authToken!).catch(handleError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken, verified]
  );

  const setOnboardFastWithdrawalAmount = (
    field: string,
    amount: number,
    resetRate?: boolean
  ) => {
    switch (field) {
      case "NGN":
        setNGN(amount);
        setUSD(undefined);
        break;
      case "USD":
        setUSD(amount);
        setNGN(undefined);
        break;

      default:
        return;
    }
    if (resetRate) setOpnQuotedRate(undefined);
  };

  const generateQuote = useCallback(
    async (
      amount: number,
      payoutCurrency: string,
      paymentChannel: ProviderPaymentChannel
    ) => {
      return getQuote(authToken!, {
        amount,
        payoutCurrency,
        paymentChannel,
      })
        .then((data) => {
          let quotedRate: QuotedRateType = {};
          if (data.quote) {
            quotedRate = {
              ...data.quote,
              rate: Math.floor(data.quote.rate! * 100) / 100,
              isQuote: true,
            };
          } else if (data.fallbackQuote) {
            quotedRate = {
              ...data.fallbackQuote,
              rate: Math.floor(data.fallbackQuote.rate! * 100) / 100,
              isFallback: true,
            };
          }
          setOpnQuotedRate(quotedRate);
          return quotedRate;
        })
        .catch(handleError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken]
  );

  const fetchRate = useCallback(async () => {
    return getReferenceRate(authToken!, "NGN", OrderType.SELL)
      .then((data) => {
        const opnRate = {
          ...data,
          price: Math.floor(data?.price! * 100) / 100,
        };
        return opnRate;
      })
      .catch(handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const fetchProviders = useCallback(async () => {
    if (!authToken || !verified) {
      return;
    }
    return getProviders(authToken!)
      .then((providers) => {
        return providers;
      })
      .catch(handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const verifyAccountNumber = useCallback(
    async (accountNumber: string, bankCode: string) => {
      setLoading(true);
      return verifyBankAccount(
        {
          accountNumber,
          bankCode,
        },
        authToken!
      )
        .then((data: NaijaAccountValidationResponse) => {
          return data;
        })
        .catch(handleError)
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken]
  );

  const initPayout = useCallback(
    async ({ currency, amount, narration, destination }: PayOutRequest) => {
      return requestPayout(
        {
          currency,
          amount,
          narration,
          destination,
        },
        authToken!
      )
        .then((data) => {
          return data;
        })
        .catch(handleError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken, verified]
  );

  const createOrder = useCallback(
    async (payload: CreateOrderRequest) => {
      return createClientOrder(authToken!, payload)
        .then((data) => {
          return data;
        })
        .catch(handleError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken, verified]
  );

  const confirmPayoutOtp = useCallback(
    async (transactionRef: string, code: string) => {
      return confirmPayout(
        transactionRef,
        {
          twoFaMethod: User2FAMethod.AUTHENTICATOR_APP,
          otpCode: code,
        },
        authToken!
      )
        .then((data) => {
          return data;
        })
        .catch(handleError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken, verified]
  );

  const confirmCryptoTransferOtp = useCallback(
    async (
      transactionRef: string,
      code: string,
      twoFaMethod: User2FAMethod
    ) => {
      return confirmCryptoTransfer(
        transactionRef,
        {
          twoFaMethod,
          otpCode: code,
        },
        authToken!
      )
        .then((data) => {
          return data;
        })
        .catch(handleError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken, verified]
  );

  useEffect(() => {
    if (!authToken || !verified) return;
    setIsFetching(true);
    try {
      getAssetNetworkTokens("usdx", authToken!).then(setTokens);

      getMyLedgerAccounts(authToken).then((data) => {
        // get users account
        const selectedAccount = data?.find(
          (account) =>
            account.currency?.toLowerCase() === "usdx" &&
            account.status?.toLowerCase() === "active"
        );
        setAccount(selectedAccount);
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsFetching(false);
    }
  }, [authToken, handleError, verified, selectedToken]);

  useEffect(() => {
    setLoading(true);
    const token = tokens?.find((token) => {
      return token.symbol === selectedToken;
    });

    // set networks
    setTokenNetworks(token?.supportedNetworks);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToken]);

  const initCryptoTransfer = useCallback(
    async (address: string, amount: number) => {
      try {
        setLoading(true);
        if (!authToken || !verified) {
          return;
        }
        const data = await initiateCryptoTransfer(
          {
            amount,
            currency: "USDX",
            destination: {
              address,
              network: selectedTokenNetwork?.code!,
              memo: selectedTokenNetwork?.networkInfo.supportsMemo
                ? ""
                : undefined,
            },
            isInternalTransfer: false,
            feeAmount: selectedTokenNetwork?.withdrawalFee!,
            narration: "External withdrawal of USD to address",
            sourceAccountId: account?.id!,
          },
          authToken!
        );

        setCryptoTransferResponse(data);
        // set otp message
        const message = `Enter OTP from ${data.twoFaMethod
          ?.map(getReadableMethods)
          .join(", ")}`;
        setOtpMessage(message);
      } catch (e: any) {
        await handleError(e);
        return { error: getErrorMessage(e) };
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken, verified, selectedToken, selectedTokenNetwork]
  );

  const values = useMemo<WithdrawalContextProps>(
    () => ({
      loading,
      isFetching,
      selectedToken,
      setSelectedToken,
      tokenNetworks,
      tokens,
      setSelectedTokenNetwork,
      setOpnQuotedRate,
      selectedTokenNetwork,
      initCryptoTransfer,
      otpMessage,
      cryptoTransferResponse,
      account,
      setOnboardFastWithdrawalAmount,
      NGN,
      USD,
      opnQuotedRate,
      generateQuote,
      fetchRate,
      getReadablePaymentChannels,
      getBanks,
      nigerianBanks,
      getBeneficiaries,
      savedBeneficiaries,
      verifyAccountNumber,
      saveABeneficiary,
      createOrder,
      initPayout,
      selectedMethod,
      setSelectedMethod,
      confirmPayoutOtp,
      confirmCryptoTransferOtp,
      fetchProviders,
      selectedProvider,
      setSelectedProvider,
    }),
    [
      loading,
      isFetching,
      selectedToken,
      tokenNetworks,
      tokens,
      selectedTokenNetwork,
      initCryptoTransfer,
      otpMessage,
      cryptoTransferResponse,
      account,
      NGN,
      USD,
      opnQuotedRate,
      generateQuote,
      fetchRate,
      getReadablePaymentChannels,
      getBanks,
      nigerianBanks,
      getBeneficiaries,
      savedBeneficiaries,
      verifyAccountNumber,
      saveABeneficiary,
      createOrder,
      initPayout,
      selectedMethod,
      confirmPayoutOtp,
      confirmCryptoTransferOtp,
      fetchProviders,
      selectedProvider,
    ]
  );

  return (
    <WithdrawalContext.Provider value={values}>
      {prop.children}
    </WithdrawalContext.Provider>
  );
};

export default WithdrawalProvider;
