import { ExchangeSvcLiquidityPoolDto } from "@nestcoinco/onboard-api-gateway-api-client";
import { useEffect, useMemo } from "react";
import { AddOutline } from "react-ionicons";
import { Link } from "react-router-dom";
import { Button, SelectNetwork, Table } from "../../../components";
import PageWrapper from "../../../components/PageWrapper";
import { allAccountsTableColumn } from "../../../constants/accounts";
import { useAccounts, useConfig } from "../../../hooks";
import MenuActionCol from "./components/MenuActionCol";

type IPoolAccount = ExchangeSvcLiquidityPoolDto & { explorerUrl: string };

const AllLiquidityPools = () => {
  const { networks } = useConfig();
  const { fetchAll, accounts, loading, isFetching } = useAccounts();

  const poolsWithExplorer: IPoolAccount[] = useMemo(
    () =>
      (accounts || [])?.map((a) => {
        const explorerUrl =
          networks?.find((n) => n.networkId === a.networkId)?.explorer ?? "";
        return { ...a, explorerUrl };
      }),
    [accounts, networks]
  );

  const columns = useMemo(
    () => [
      ...allAccountsTableColumn(networks),
      {
        Header: "Actions",
        accessor: "actions",
        className: "text-center",
        Cell: MenuActionCol,
      },
    ],
    [networks]
  );

  const handleNetworkChange = (sn: string) => {
    fetchAll(sn === "all" ? undefined : sn);
  };

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  return (
    <PageWrapper title="Liquidity accounts">
      <div className="mt-7">
        <div className="mt-7">
          <div className="flex justify-end my-6 gap-5">
            <div className="w-72">
              <SelectNetwork
                value="all"
                variant="outlined"
                label="Select network"
                allowAllFilter
                onChange={(v) => handleNetworkChange(v!.toString())}
                size="lg"
              />
            </div>
            <div>
              <Link to="/liquidity-pools/new">
                <Button
                  className="py-[10px]"
                  text="Create liquidity account"
                  icon={<AddOutline color="inherit" />}
                  fullWidth
                />
              </Link>
            </div>
          </div>
          <Table
            columns={columns}
            data={poolsWithExplorer}
            loading={loading || isFetching}
            noResultMessage="No record found. Create liquidity account."
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default AllLiquidityPools;
