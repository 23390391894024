import React, { FC, ReactNode } from "react";
import Loader from "../Loader";
import classNames from "classnames";
import { variantToColorClassMap } from "../../constants/button";

export type ButtonVariant = "default" | "outline" | "soft" | "ghost" | "text";
export type ButtonType = "default" | "success" | "warning" | "info" | "danger";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  text?: string;
  variant?: ButtonVariant;
  color?: ButtonType;
  loading?: boolean;
  fullWidth?: boolean;
  rounded?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
}

const Button: FC<ButtonProps> = ({
  text,
  variant = "default",
  color = "default",
  loading,
  disabled,
  rounded,
  icon,
  fullWidth,
  className,
  children,
  ...restProps
}) => {
  const typeClass = variantToColorClassMap[variant][color];

  const classnames = classNames([
    "tracking-[0.02rem] px-5 leading-6 py-4 rounded-[10px] text-base font-medium text-center flex items-center",
    className,
    {
      "text-white": variant === "default",
      "bg-transparent border hover:text-white": variant === "outline",
      "bg-white": variant === "ghost",
      "bg-transparent": variant === "text",
    },
    {
      "bg-gray-300 !text-gray-500 cursor-not-allowed pointer-events-none":
        disabled || loading,
      "!rounded-full": rounded,
      "w-full": fullWidth,
      "justify-between": !!icon,
      "justify-center": !!!icon,
    },
    typeClass,
  ]);

  return (
    <button disabled={disabled} className={classnames} {...(restProps as any)}>
      {text ? text : children}{" "}
      {loading ? (
        <span className="ml-2">
          <Loader />
        </span>
      ) : (
        <span className="ml-2">{icon}</span>
      )}
    </button>
  );
};

export default Button;
