import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AuthCard, Button, Input } from "../../../components";
import { useAuth, useQuery } from "../../../hooks";
import { acceptInviteSchema } from "../../../schemas/auth";
import PhoneIcon from "../../../icons/PhoneIcon";
import { externalLinks } from "../../../constants/links";

const AcceptInvite = () => {
  const { token } = useQuery();
  const { acceptInvitation } = useAuth();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: acceptInviteSchema,
    onSubmit: async (values) => {
      const response = await acceptInvitation(token, values.password);
      if (response?.error) {
        const error = response.error as any;
        return addToast(error.message as any, { appearance: "error" });
      }

      navigate("/auth/2fa-setup");
    },
  });

  return (
    <AuthCard icon={<PhoneIcon />} title="Set a password for your profile">
      <form className="mt-8" onSubmit={formik.handleSubmit}>
        <Input
          label="Enter password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          required
          type="password"
          error={formik.errors.password}
        />
        <Input
          label="Confirm password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          required
          type="password"
          error={formik.errors.confirmPassword}
        />
        <Button
          text=" Set Password"
          type="submit"
          className="mt-4 mb-1"
          fullWidth
          loading={formik.isSubmitting}
        />
      </form>
      <p className="text-sm pt-3 text-center">
        By clicking continue, you accept Onboard’s{" "}
        <a
          className="text-primary underline underline-offset-2"
          href={externalLinks.Terms}
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          className="text-primary underline underline-offset-2"
          href={externalLinks.Privacy}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>{" "}
        .
      </p>
    </AuthCard>
  );
};

export default AcceptInvite;
