import { FC, useState } from "react";
import classnames from "classnames";
import Button, { ButtonType, ButtonVariant } from "../Button";
import { CaretDownOutline } from "react-ionicons";

export type DropdownItem = {
  icon?: any;
  value?: string;
  text: string;
};
interface DropDownProps {
  placeholder?: string;
  items: DropdownItem[];
  value?: DropdownItem;
  setValue: (item: DropdownItem, index: number) => void;
  variant?: ButtonVariant;
  color?: ButtonType;
  caretColor?: string;
  className?: string;
}

const Dropdown: FC<DropDownProps> = ({
  placeholder = "Select an Item",
  caretColor,
  items,
  value,
  setValue,
  variant,
  color,
  className,
}) => {
  const [open, setOpen] = useState(false);

  const handleItemClick = (item: DropdownItem, index: number) => {
    setValue(item, index);
    setOpen(false);
  };

  return (
    <div className={`w-full relative ${className}`}>
      <Button
        type="button"
        className={className}
        onClick={() => setOpen((v) => !v)}
        variant={variant}
        color={color}
        text={value?.text || placeholder}
        fullWidth
        icon={
          <CaretDownOutline
            color={caretColor || "white"}
            cssClasses={classnames([
              "transition duration-200",
              {
                "rotate-180": open,
              },
            ])}
          />
        }
      />

      <div
        className={classnames([
          "transition-all duration-200 absolute w-full overflow-hidden ease-out",
          {
            "!h-0": !open,
          },
        ])}
        style={{ zIndex: 99 }}
      >
        <ul
          className="list  border border-grey-100 shadow-shadow-
             rounded-[4px]  mt-1"
        >
          {items.map((item, i) => (
            <li
              key={i}
              onClick={() => handleItemClick(item, i)}
              className="icon group px-3 py-4 cursor-pointer bg-white hover:bg-blue-100 rounded-[4px] flex items-center gap-x-3 transition duration-200"
            >
              <div>{item.icon}</div>
              <p className="text-slate-600 text-xs font-normal group-hover:text-blue-700">
                {item.text}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Dropdown;
