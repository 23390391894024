import { Option, Select, SelectProps } from '@material-tailwind/react';
import React, { useMemo } from 'react';
import {CryptoAssetDto, FiatDto} from "@nestcoinco/onboard-api-gateway-api-client";

interface IProp extends Pick<SelectProps, 'value' | 'variant' | 'onChange' | 'label' | 'size' | 'disabled'> {
  allowAllFilter?: boolean;
  symbolType: 'FIAT' | 'CRYPTO',

  assets: CryptoAssetDto[] | FiatDto[]
}

interface ISymbolOptions {
  value: any;
  label: string;
  url?: string;
}

const buildSymbolOptionsFromCrypto = (cryptoAssets: CryptoAssetDto[], _options: ISymbolOptions[]) => {
  for (const { symbol, name, logo } of (cryptoAssets || [])) {
    _options.push({
      label: `${name} (${symbol})`,
      value: symbol,
      url: logo
    });
  }
  return _options;
}

const buildSymbolOptionsFromFiat = (fiats: FiatDto[], _options: ISymbolOptions[]) => {
  for (const { isoCode: symbol, name, logo } of (fiats || [])) {
    _options.push({
      label: `${name} (${symbol})`,
      value: symbol,
      url: logo
    });
  }
  return _options;
}

const SelectSymbol: React.FC<IProp> = ({ allowAllFilter, symbolType, assets, ...rest }) => {
  const options = useMemo(() => {
    let _options: ISymbolOptions[] = [];

    if (allowAllFilter) {
      _options.push({ value: '', label: `Any currency` });
    }

    if (symbolType === 'CRYPTO') {
      return buildSymbolOptionsFromCrypto(assets as CryptoAssetDto[], _options);
    } else {
      return buildSymbolOptionsFromFiat(assets as FiatDto[], _options);
    }


  }, [allowAllFilter, symbolType, assets]);

  return (
    <Select {...rest} color='gray'>
      {options.map(({ value, label }, index) => (
        <Option value={value} key={index}>
          {label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectSymbol;
