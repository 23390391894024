import UrlBox from '../UrlBox';
import useUrls from '../../../../../hooks/useUrls';
import { Card, TooltipWithCTA } from '../../../../../components';

const WebhookUrl = () => {
  const { webhookUrl, setWebhookUrl, upsertWebhookUrl } = useUrls();

  return (
    <Card title='Webhook Url' classNames='mt-7'>
      <div className='text-[14px] mt-3'>
        Update{' '}
        <TooltipWithCTA title='Webhook notifications will be sent to this URL.'>
          webhook url
        </TooltipWithCTA>{' '}
        here
      </div>
      <UrlBox
        url={webhookUrl}
        inputPlaceholder='Webhook URL'
        updateStateUrl={setWebhookUrl}
        submitContextHandler={upsertWebhookUrl}
      />
    </Card>
  );
};

export default WebhookUrl;
