import { useAuth } from '../../hooks';
import { useToasts } from 'react-toast-notifications';
import { ContextActionReturnType } from '../../typings/interfaces/context';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  getCexConfigs,
  getErrorMessage,
  upsertTradeAuthorizationUrl,
  upsertTradeWebhookUrl,
  upsertTradeAutoExecuteUrl,
} from '../../api';

interface UrlContextProps {
  loading: boolean;
  isFetching: boolean;
  webhookUrl?: string;
  autoExecUrl?: string;
  authorizationUrl?: string;
  autoExecuteTrade?: boolean;
  setAutoExecUrl: Dispatch<SetStateAction<string>>;
  setWebhookUrl: Dispatch<SetStateAction<string>>;
  setAuthorizationUrl: Dispatch<SetStateAction<string>>;
  upsertWebhookUrl: (url: string) => Promise<ContextActionReturnType | void>;
  upsertAutoExecUrl: (url: string) => Promise<ContextActionReturnType | void>;
  upsertAuthorizationUrl: (url: string) => Promise<ContextActionReturnType | void>;
}

export const UrlContext = createContext<UrlContextProps | null>(null);

const UrlProvider = (prop: any) => {
  const [autoExecuteTrade, setAutoExecuteTrade] = useState<boolean>();
  const [autoExecUrl, setAutoExecUrl] = useState<string>('');
  const [webhookUrl, setWebhookUrl] = useState<string>('');
  const [authorizationUrl, setAuthorizationUrl] = useState<string>('');

  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const { authToken, verified } = useAuth();
  const { addToast } = useToasts();

  const handleError = useCallback(
    (e: any) => {
      const error = getErrorMessage(e).message;
      addToast(error, { appearance: 'error' });
    },
    [addToast]
  );

  const getUrls = useCallback(async () => {
    if (!authToken || !verified) return;
    const { authorizationUrl, webhookUrl, executionUrl, autoExecuteTrade } = (await getCexConfigs(
      authToken!
    ))!;
    setWebhookUrl(webhookUrl ?? '');
    setAutoExecUrl(executionUrl ?? '');
    setAuthorizationUrl(authorizationUrl ?? '');
    setAutoExecuteTrade(autoExecuteTrade);
  }, [authToken, verified]);

  useEffect(() => {
    setIsFetching(true);
    getUrls()
      .catch(handleError)
      .finally(() => setIsFetching(false));
  }, [getUrls, handleError]);

  const upsertAuthorizationUrl = useCallback(
    async (url: string) => {
      setLoading(true);
      try {
        if (!authToken) {
          return { error: 'Authentication session not found!' };
        }
        await upsertTradeAuthorizationUrl(url, authToken!);
        addToast('Trade authorization URL updated', { appearance: 'success' });
      } catch (e) {
        return { error: getErrorMessage(e) };
      } finally {
        setLoading(false);
      }
    },
    [authToken, addToast]
  );

  const upsertWebhookUrl = useCallback(
    async (url: string) => {
      setLoading(true);
      try {
        if (!authToken) {
          return { error: 'Authentication session not found!' };
        }
        await upsertTradeWebhookUrl(url, authToken!);
        addToast('Webhook URL updated', { appearance: 'success' });
      } catch (e) {
        return { error: getErrorMessage(e) };
      } finally {
        setLoading(false);
      }
    },
    [authToken, addToast]
  );

  const upsertAutoExecUrl = useCallback(
    async (url: string) => {
      setLoading(true);
      try {
        if (!authToken) {
          return { error: 'Authentication session not found!' };
        }
        await upsertTradeAutoExecuteUrl(url, authToken!);
        addToast('Trade execution URL updated', { appearance: 'success' });
      } catch (e) {
        return { error: getErrorMessage(e) };
      } finally {
        setLoading(false);
      }
    },
    [authToken, addToast]
  );

  const values = useMemo<UrlContextProps>(
    () => ({
      loading,
      isFetching,
      webhookUrl,
      autoExecUrl,
      authorizationUrl,
      setAutoExecUrl,
      setWebhookUrl,
      setAuthorizationUrl,
      upsertWebhookUrl,
      upsertAutoExecUrl,
      autoExecuteTrade,
      upsertAuthorizationUrl,
    }),
    [
      loading,
      isFetching,
      webhookUrl,
      autoExecUrl,
      authorizationUrl,
      upsertWebhookUrl,
      upsertAutoExecUrl,
      autoExecuteTrade,
      upsertAuthorizationUrl,
    ]
  );

  return <UrlContext.Provider value={values}>{prop.children}</UrlContext.Provider>;
};

export default UrlProvider;
