import useOrder from "../../../hooks/useOrder";
import { useEffect, useMemo, useState } from "react";
import { ContextActionReturnType, OrderStatus } from "../../../typings";
import {
  ExchangeOrderDTO,
  ExchangeOrdersResponse,
} from "@nestcoinco/onboard-api-gateway-api-client";
import PageWrapper from "../../../components/PageWrapper";
import { Button, SelectNetwork, Table } from "../../../components";
import { orderColumn } from "../../../constants/order";
import { useConfig } from "../../../hooks";
import { IOrderFilter } from "../../../api/order";
import { RefreshCircleOutline } from "react-ionicons";
import SelectSymbol from "../../../components/SelectSymbol";
import FilterSelect from "../../../components/Select";

const statusArr = [
  "INITIATED",
  "DEPOSITED",
  "CONFIRMED",
  "COMPLETED",
  "PENDING",
  "CANCELLED",
  "IN_DISPUTE",
  "AWAITING_ACCEPTANCE",
].map((status) => ({
  value: status,
  label: status
    .replace("_", " ")
    .toLowerCase()
    .replace(/^\w{1}/, (firstLetter) => firstLetter.toUpperCase()),
}));

const AllOrders = () => {
  const DEFAULT_PAGE_SIZE = 10;
  const [bufferFilter, setBufferFilter] = useState<Partial<IOrderFilter>>({
    size: DEFAULT_PAGE_SIZE,
  });

  const { networks, fiats, cryptoAssets } = useConfig();
  const [orders, setOrders] = useState<ExchangeOrderDTO[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [more, setMore] = useState(false);
  const [orderFilter, setOrderFilter] = useState<IOrderFilter>({
    ...bufferFilter,
  });
  const { fetchOrders, loading: isFetching } = useOrder();

  const columns = useMemo(() => orderColumn(networks!), [networks]);

  useEffect(() => {
    fetchOrders(orderFilter)
      .then(
        (orders: ContextActionReturnType<ExchangeOrdersResponse> | void) => {
          if (orders) {
            const { data } = orders;
            setOrders(data?.orders!);
            setMore(data?.totalPages! - 1 > data?.pageNumber!);
          } else {
            setOrders([]);
          }
        }
      )
      .catch((e) => {
        setOrders([]);
      });
  }, [fetchOrders, orderFilter]);

  const handleNetworkChange = (sn: string) => {
    setBufferFilter({ ...bufferFilter, networkId: sn });
  };
  const handleFiatChange = (sf: string) => {
    setBufferFilter({ ...bufferFilter, fiat: sf });
  };
  const handleCryptoChange = (sc: string) => {
    setBufferFilter({ ...bufferFilter, asset: sc });
  };
  const handleStatusChange = (sc: string) => {
    setBufferFilter({ ...bufferFilter, statuses: [sc as OrderStatus] });
  };

  return (
    <PageWrapper title="Orders">
      <div className="mt-12">
        <div className="mt-7">
          <div className="flex justify-end my-6 gap-5">
            <div className="">
              <SelectNetwork
                value="all"
                variant="outlined"
                label="Select network"
                allowAllFilter
                onChange={(v) => handleNetworkChange(v!.toString())}
                size="lg"
              />
            </div>
            <div className="">
              <FilterSelect
                value="all"
                filterOptions={[...statusArr]}
                variant="outlined"
                label="Select status"
                allowAllFilter
                onChange={(v) => handleStatusChange(v!.toString())}
                size="lg"
              />
            </div>
            <div className="">
              <SelectSymbol
                value="all"
                variant="outlined"
                label="Select fiat"
                allowAllFilter
                size="lg"
                symbolType={"FIAT"}
                assets={fiats!}
                onChange={(v) => handleFiatChange(v!.toString())}
              />
            </div>
            <div className="">
              <SelectSymbol
                value="all"
                variant="outlined"
                label="Select crypto"
                allowAllFilter
                size="lg"
                symbolType={"CRYPTO"}
                assets={cryptoAssets!}
                onChange={(v) => handleCryptoChange(v!.toString())}
              />
            </div>
            <div>
              <Button
                className="py-[10px]"
                text=""
                icon={
                  <RefreshCircleOutline color="inherit" rotate={isFetching} />
                }
                onClick={() => setOrderFilter({ ...bufferFilter })}
                fullWidth
              />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          data={orders}
          loading={isFetching}
          noResultMessage="No orders found."
          title="Orders"
        />
      </div>
    </PageWrapper>
  );
};

export default AllOrders;
