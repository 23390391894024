import { FC } from "react";

export interface MiddleWareExecutorProps {
  children: any;
  middlewares: (() => boolean)[];
}

const MiddleWareExecutor: FC<MiddleWareExecutorProps> = ({
  children,
  middlewares,
}) => {
  for (let i = 0; i < middlewares.length; i++) {
    const middleware = middlewares[i];
    const next = middleware();
    if (!next) {
      break;
    }
  }

  return children;
};

export default MiddleWareExecutor;
