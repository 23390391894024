import { useNavigate } from "react-router-dom";
import PageWrapper from "../../../components/PageWrapper";
import useWithdrawal from "../../../hooks/useWithdrawal";
import { PayoutsSvcProviderPaymentChannel } from "@nestcoinco/onboard-api-gateway-api-client";

import { ArrowBackOutline } from "react-ionicons";
import BankTransfer from "../../../components/Withdrawal/BankTransfer";
import P2PWallet from "../../../components/Withdrawal/P2PWallet";

const CompleteOnboardFast = () => {
  const { selectedMethod } = useWithdrawal();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const BankTransferSelected = () => {
    return (
      selectedMethod?.value === PayoutsSvcProviderPaymentChannel.BANK_TRANSFER
    );
  };

  return (
    <PageWrapper title="Complete Withdrawal">
      <p className="mt-4 flex cursor-pointer" onClick={handleBack}>
        <ArrowBackOutline /> Back{" "}
      </p>
      {BankTransferSelected() ? <BankTransfer /> : <P2PWallet />}
    </PageWrapper>
  );
};

export default CompleteOnboardFast;
