import useAuth from "../useAuth";

const useProductAccess = () => {
  const { business } = useAuth();

  const hasOpnProduct = business?.enabledProducts?.includes("opn");
  const hasNonDefiOnboardProduct = business?.enabledProducts?.includes(
    "onboard-connect-non-defi"
  );
  const hasDefiOnboardProduct =
    business?.enabledProducts?.includes("onboard-connect");

  return { hasDefiOnboardProduct, hasNonDefiOnboardProduct, hasOpnProduct };
};

export default useProductAccess;
