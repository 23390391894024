import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../hooks";
import { getNetworkTokens } from "../../../api/accounts";
import { getErrorMessage } from "../../../api";
import { useToasts } from "react-toast-notifications";
import { Button, Loader } from "../../../components";
import { useDepositContext } from "..";
import BackButton from "../../../components/Button/BackButton";

const SelectStableCoin = () => {
  const { addToast } = useToasts();
  const { authToken, verified } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [networkTokens, setNetworkTokens] = useState<any | undefined>();
  const { dispatch } = useDepositContext();

  const handleError = useCallback(
    (e: any) => {
      const error = getErrorMessage(e).message;
      addToast(error, { appearance: "error" });
    },
    [addToast]
  );

  useEffect(() => {
    if (!authToken || !verified) return;
    setIsLoading(true);
    getNetworkTokens(authToken)
      .then(setNetworkTokens)
      .catch(handleError)
      .finally(() => {
        setIsLoading(false);
      });
  }, [authToken, handleError, verified]);

  return (
    <div className="mx-auto w-[450px]">
      <div className="mt-14 ">
        <BackButton handleClick={() => dispatch({ step: "method" })} />
      </div>
      <div className="mt-6 bg-white p-10 rounded-[10px]">
        <h1 className="text-center font-semibold pb-6">Select stable coin</h1>
        <div className="flex flex-col gap-3 rounded-md">
          {isLoading ? (
            <div className="flex justify-center">
              <Loader color="blue-600" />{" "}
            </div>
          ) : (
            networkTokens?.tokens?.map((token: any) => {
              const { symbol, supportedNetworks } = token;
              return (
                <Button
                  key={token.name}
                  onClick={() => {
                    dispatch({
                      selectedToken: {
                        name: symbol,
                        networks: supportedNetworks,
                      },
                    });
                    dispatch({ step: "network" });
                  }}
                  className={classNames(
                    "height-[150px] grid place-items-center rounded-md",
                    {
                      "text-primary-300 border border-primary-300": true,
                    }
                  )}
                  text={symbol}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectStableCoin;
