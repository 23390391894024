import { Account, Metadata } from "@nestcoinco/onboard-api-gateway-api-client";

export function getTotalBalances(accounts: Account[]) {
  const result: any = {};
  accounts.forEach((account) => {
    result[account.currency!] = {
      availableBalance: account?.availableBalance,
    };
  });

  return result;
}

export const getValueFromMetadata = (name: string, metadata: Metadata[]) => {
  // loop metadata
  const data = metadata?.find((data) => {
    return data.name === name;
  });
  return data?.value;
};
