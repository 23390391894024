import { Typography } from "@material-tailwind/react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AuthCard, Button, Input } from "../../../components";
import { useAuth } from "../../../hooks";
import { passwordResetRequestSchema } from "../../../schemas/auth";

const ForgotPassword = () => {
  const { requestPasswordReset } = useAuth();
  const { addToast } = useToasts();
  const initialValues = {
    email: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: passwordResetRequestSchema,
    onSubmit: async (values) => {
      const response = await requestPasswordReset(values.email);
      if (response?.error) {
        return addToast(response.error as any, { appearance: "error" });
      }

      addToast("Password reset link has been sent to your email", {
        appearance: "success",
      });
      formik.resetForm();
    },
  });

  return (
    <AuthCard
      title="Forgot Password?"
      subtitle="Please provide the email to your account"
    >
      <form onSubmit={formik.handleSubmit}>
        <Input
          label="Enter email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          required
          type="email"
          error={formik.errors.email}
        />
        <Button
          text="Reset my password"
          type="submit"
          className="mt-4 mb-1"
          fullWidth
          loading={formik.isSubmitting}
        />
        <Typography>
          Back to{" "}
          <Link className="text-blue-500" to="/auth/login">
            Login
          </Link>
        </Typography>
      </form>
    </AuthCard>
  );
};

export default ForgotPassword;
