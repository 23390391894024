import UrlBox from '../UrlBox';
import useUrls from '../../../../../hooks/useUrls';
import { Card, TooltipWithCTA } from '../../../../../components';

const AuthorizationUrl = () => {
  const { authorizationUrl, setAuthorizationUrl, upsertAuthorizationUrl } = useUrls();


  return (
    <Card title='Authorization Url' classNames='mt-7'>
      <div className='text-[14px] mt-3'>
        Update{' '}
        <TooltipWithCTA title='Order notification to authorize trade will be sent to this URL.'>
          authorization url
        </TooltipWithCTA>{' '}
        here
      </div>
      <UrlBox
        url={authorizationUrl}
        key={authorizationUrl}
        inputPlaceholder='Authorization URL'
        updateStateUrl={setAuthorizationUrl}
        submitContextHandler={upsertAuthorizationUrl}
      />
    </Card>
  );
};

export default AuthorizationUrl;
