import React, { Fragment } from 'react';
import { Loader } from '../../../../components';
import useUrls from '../../../../hooks/useUrls';
import AuthorizationUrl from './AuthorizationUrl';
import TradeExecutionUrl from './TradeExecutionUrl';
import WebhookUrl from './WebhookUrl';

const URL: React.FC<unknown> = () => {
  const { isFetching, autoExecuteTrade } = useUrls();
  return (
    <Fragment>
      {!isFetching ? (
        <Fragment>
          <WebhookUrl />
          {autoExecuteTrade ? <AuthorizationUrl /> : <TradeExecutionUrl />}
        </Fragment>
      ) : (
        <div className='w-full h-[200px] flex justify-center items-center'>
          <Loader />
        </div>
      )}
    </Fragment>
  );
};

export default URL;
