import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => {
    const query = new URLSearchParams(search);
    const keyValuePair: Record<string, string> = {};

    query.forEach((value, key) => {
      keyValuePair[key] = value;
    });

    return keyValuePair;
  }, [search]);
};

export default useQuery;
