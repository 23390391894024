import { Option, Select, SelectProps } from '@material-tailwind/react';
import React, { useMemo } from 'react';

interface IFilterOptions {
  value: any;
  label: string;
}

interface IProp extends Pick<SelectProps, 'value' | 'variant' | 'onChange' | 'label' | 'size' | 'disabled'> {
  allowAllFilter?: boolean;
  filterOptions: IFilterOptions[];
}

const FilterSelect: React.FC<IProp> = ({ allowAllFilter, filterOptions, ...rest }) => {
  const options = useMemo(() => {
    let _options: IFilterOptions[] = [];

    if (allowAllFilter) {
      _options.push({ value: '', label: 'All' });
    }

    for (const { label, value } of filterOptions || []) {
      _options.push({
        label,
        value
      });
    }
    return _options;
  }, [filterOptions, allowAllFilter]);

  return (
    <Select {...rest} color='gray'>
      {options.map(({ value, label }, index) => (
        <Option value={value} key={index}>
          {label}
        </Option>
      ))}
    </Select>
  );
};

export default FilterSelect;
