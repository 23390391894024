import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../hooks";

const useCheckToken = () => {
  const { token } = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) return navigate("/auth/login");
  }, [token, navigate]);

  return true;
};

export default useCheckToken;
