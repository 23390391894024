import { useFormik } from 'formik';
import React, { FC, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Button, Input } from '../../../../../components';
import { upsertUrlSchema } from '../../../../../schemas/urls';
import { ContextActionReturnType } from '../../../../../typings/interfaces/context';

export enum InputState {
  Add = 'Add',
  Change = 'Change',
  Update = 'Update',
}

interface IUrlBox {
  url?: string;
  inputPlaceholder?: string;
  updateStateUrl?: React.Dispatch<React.SetStateAction<string>>;
  submitContextHandler: (url: string) => Promise<ContextActionReturnType | void>
}

const UrlBox: FC<IUrlBox> = ({ submitContextHandler, updateStateUrl, url, inputPlaceholder }) => {
  const { addToast } = useToasts();
  const initialValues = { url: url || '' };
  const [updateMode, setUpdateMode] = useState<boolean>(false);

  const inputState = useMemo(
    () => (updateMode ? InputState.Update : !url ? InputState.Add : InputState.Change),
    [updateMode, url]
  );

  const handleSubmit = async (_url: string) => {
    const response = await submitContextHandler(_url);
    if (response?.error) {
      return addToast(response.error as any, { appearance: 'error' });
    }
    updateStateUrl && updateStateUrl(_url);
    setUpdateMode(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: upsertUrlSchema,
    onSubmit: (values) => handleSubmit(values.url),
  });

  const handleButtonClick = () => {
    switch (inputState) {
      case InputState.Add:
      case InputState.Change:
        setUpdateMode(true);
        break;
      case InputState.Update:
        formik.submitForm();
    }
  };

  return (
    <div className='flex items-center mt-3 justify-items-between'>
      <div className='grow'>
        <Input
          required
          type='url'
          name='url'
          bottomPad='0'
          error={formik.errors.url}
          value={formik.values.url}
          onChange={formik.handleChange}
          placeholder={inputPlaceholder}
          disabled={inputState !== InputState.Update}
        />
      </div>
      <div className='ml-3'>
        <Button
          type='button'
          text={inputState}
          onClick={handleButtonClick}
          loading={formik.isSubmitting}
        />
      </div>
    </div>
  );
};

export default UrlBox;
