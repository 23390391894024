import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, DropDown } from "..";
import useWithdrawal from "../../hooks/useWithdrawal";
import { DropdownItem } from "../Dropdown";
import {
  Beneficiary,
  NaijaAccountValidationResponse,
} from "@nestcoinco/onboard-api-gateway-api-client";
import { QuotedRateType } from "../../providers/WithdrawalProvider";
import { v4 as uuidv4 } from "uuid";
import { extractUserName } from "../../lib/withdrawal";
import Search from "../Table/components/Search";

interface IState {
  usd: number;
  ngn: number;
  opnQuotedRate: QuotedRateType;
}

const BankTransfer = () => {
  const state = useLocation().state as IState;
  const {
    account,
    getBanks,
    nigerianBanks,
    savedBeneficiaries,
    getBeneficiaries,
    verifyAccountNumber,
    saveABeneficiary,
  } = useWithdrawal();

  const [accountNumber, setAccountNumber] = useState("");
  const [verifiedAccount, setVerifiedAccount] =
    useState<void | NaijaAccountValidationResponse>();
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] =
    useState<void | Beneficiary>();
  const [checkSaveBeneficiary, setCheckSaveBeneficiary] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);

  const protectAccount = (accountNumber: string) => {
    return `xxxx${accountNumber.substring(accountNumber.length - 4)}`;
  };

  useEffect(() => {
    setBeneficiaries(savedBeneficiaries);
  }, [savedBeneficiaries]);

  useEffect(() => {
    if (!state.usd) {
      navigate("/withdrawal/onboard-fast");
    }
    getBanks();
    getBeneficiaries();
  }, [state]);
  useEffect(() => {
    if (!account) {
      return;
    }
    getBanks();
    getBeneficiaries();
  }, [account]);

  const [selectedBank, setSelectedBank] = useState<DropdownItem>();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleVerify = (acctNum: string, bankCode: string) => {
    if (acctNum.length === 10 && bankCode) {
      // make call
      setIsLoading(true);
      verifyAccountNumber(acctNum, bankCode)
        .then((data) => {
          setVerifiedAccount({
            ...data,
            bankName: data?.bankName || selectedBank?.text!,
            bankCode: data?.bankCode || selectedBank?.value,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleAccountInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setAccountNumber(value);
    setVerifiedAccount(undefined);
    if (selectedBank?.text && !selectedBank?.value) {
      setSelectedBank({
        icon: "",
        text: "",
        value: "",
      });
    }

    handleVerify(value, selectedBank?.value!);
  };
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;

    setCheckSaveBeneficiary(value);
  };

  const handleInitiatePayout = async () => {
    if (!verifiedAccount) return;

    setIsLoading(true);

    if (checkSaveBeneficiary) {
      // save beneficairy
      saveABeneficiary({
        accountName: verifiedAccount.accountName!,
        bankName: verifiedAccount.bankName! || selectedBank?.text!,
        accountNumber: verifiedAccount.accountNumber!,
        bankCode: verifiedAccount.bankCode || selectedBank?.value!,
        currency: "NGN",
      });
    }

    navigate("/withdrawal/onboard-fast/confirm", {
      state: {
        data: {
          quoteId: state.opnQuotedRate.id,
          amount: state.usd,
          reference: uuidv4(),
          type: "SELL",
          recipient: {
            email: recipientEmail,
            phoneNumber: recipientPhone,
            firstName: extractUserName(
              verifiedAccount.accountName!,
              "firstName"
            ),
            lastName: extractUserName(verifiedAccount.accountName!, "lastName"),
            recipientType: "BankAccountRecipient",
            bankId: verifiedAccount.bankCode || selectedBank?.value,
            accountNumber: verifiedAccount.accountNumber,
          },
        },
        ...state,
        verifiedAccount,
      },
    });
  };

  const handleDropdownChange = (item: DropdownItem): void => {
    setSelectedBank(item);
    setVerifiedAccount(undefined);
    handleVerify(accountNumber, item?.value!);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecipientEmail(e.target.value);
  };
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecipientPhone(e.target.value);
  };

  const handleSearchBeneficiary = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setSearchValue(keyword);
    const searchResult = savedBeneficiaries.filter((savedBeneficiary) => {
      return savedBeneficiary.accountName
        ?.toLowerCase()
        .includes(keyword.toLowerCase());
    });
    setBeneficiaries(searchResult);
  };

  return (
    <>
      <div className="flex justify-center w-full">
        <Card
          title="Enter account details"
          headingClassNames="mb-8 text-center"
          classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
          children={
            <>
              <div className="w-full">
                <div className="w-full border rounded my-6 bg-gray-200">
                  <DropDown
                    items={nigerianBanks}
                    setValue={handleDropdownChange}
                    placeholder="Select bank"
                    variant="text"
                    caretColor="black"
                    className="!text-black"
                    value={selectedBank}
                  />
                </div>
                <div className="p-3" />
                <input
                  className={
                    "appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  }
                  type="text"
                  pattern="^[0-9]{10}$"
                  placeholder="Account Number"
                  onChange={handleAccountInput}
                  maxLength={10}
                  disabled={!selectedBank}
                  value={verifiedAccount?.accountNumber || accountNumber}
                />
                <div className="p-3" />
                <input
                  className={
                    "appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  }
                  type="text"
                  disabled
                  placeholder="Account name"
                  value={verifiedAccount?.accountName || ""}
                />
                <div className="p-3" />
                <input
                  className={
                    "appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  }
                  type="email"
                  placeholder="Email"
                  onChange={handleEmailChange}
                  value={recipientEmail}
                />
                <div className="p-3" />
                <input
                  className={
                    "appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                  }
                  type="tel"
                  placeholder="Phone number"
                  onChange={handlePhoneChange}
                  value={recipientPhone}
                />
                <div className="p-3" />
                <input
                  type="checkbox"
                  checked={checkSaveBeneficiary}
                  onChange={handleCheck}
                />{" "}
                Save beneficiary
                <div className="p-3" />
                <Button
                  loading={isLoading}
                  className="w-full"
                  text={"Continue"}
                  disabled={
                    !verifiedAccount || !recipientEmail || !recipientPhone
                  }
                  onClick={handleInitiatePayout}
                />
              </div>
            </>
          }
        />
      </div>
      {savedBeneficiaries.length ? (
        <>
          <div className="flex justify-center w-full">
            <div className="mt-8 text-center pb-2 flex justify-between items-center min-w-[400px] max-w-[500px] w-full">
              <p>Saved beneficiaries</p>
              <Search value={searchValue} setValue={handleSearchBeneficiary} />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <Card
              classNames="py-5 px-6 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
              children={
                <>
                  {beneficiaries.map((savedBeneficiary) => {
                    return (
                      <div
                        className="border border-gray-700 rounded flex flex-col items-center py-2 my-3 cursor-pointer"
                        onClick={(e) => {
                          setSelectedBank({
                            icon: "",
                            text: savedBeneficiary.bankName!,
                            value: savedBeneficiary.bankCode!,
                          });

                          setVerifiedAccount(savedBeneficiary);
                          setSelectedBeneficiary(savedBeneficiary);
                        }}
                        key={savedBeneficiary.accountNumber}
                      >
                        <p>{savedBeneficiary.accountName}</p>
                        <p>
                          {savedBeneficiary.bankName}{" "}
                          {protectAccount(savedBeneficiary?.accountNumber!)}
                        </p>
                      </div>
                    );
                  })}
                </>
              }
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default BankTransfer;
