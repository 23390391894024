import { FC, useState } from "react";
import { InfoCircleIcon } from "../Icons";
import classnames from "classnames";

interface TooltipWithCtaProps {
  children: any;
  title: string;
  description?: string;
  ctaText?: string;
  cta?: () => void;
}

const TooltipWithCta: FC<TooltipWithCtaProps> = ({
  title,
  description,
  ctaText,
  cta,
  children,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <span
      className="inline relative underline underline-offset-2 cursor-pointer"
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
    >
      <div
        className={classnames([
          "absolute bottom-[16px] left-0 transition-all duration-500",
          {
            "opacity-1": open,
            "opacity-0": !open,
          },
        ])}
      >
        <div className="mt-7 mb-4 shadow-md container relative min-w-[300px] px-4 py-4 bg-white rounded">
          <div className="flex gap-2">
            <InfoCircleIcon />

            <p className="text-sm font-semibold leading-4 text-gray-800">
              {title}
            </p>
          </div>
          {description ? (
            <p className="text-xs leading-4 text-gray-600 pt-2 pb-2">
              {description}
            </p>
          ) : null}
          {cta && ctaText ? (
            <div className="pt-3">
              <button
                onClick={cta}
                className="text-xs font-medium tracking-[0.02rem] leading-3 text-center text-white px-3 py-2 bg-blue-700 rounded-md w-full hover:bg-indigo-600 transform duration-300 ease-in-out"
              >
                {ctaText}
              </button>
            </div>
          ) : null}
          <svg
            className="absolute z-10 bottom-[-10px]"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 10L0 0L16 1.41326e-06L8 10Z" fill="white" />
          </svg>
        </div>
      </div>
      {children}
    </span>
  );
};

export default TooltipWithCta;
