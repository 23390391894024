import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export const passwordResetRequestSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

export const acceptInviteSchema = Yup.object().shape({
  password: Yup.string()
    .test(
      "len",
      "password length must be greater than or equals 8 and less than or equals 30",
      (value) => {
        const len = String(value)?.length || 0;
        return len >= 8 && len <= 30;
      }
    )
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%^*?&-])[A-Za-z\d@$#!%^*?&-]/g,
      "password must contain at least one upper-case, lower-case, number and special character"
    ),
  confirmPassword: Yup.string().test(
    "passwords-match",
    "Password must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
});

export const resetPasswordSchema = acceptInviteSchema;

export const twoFAVerificationSchema = Yup.object().shape({
  twoFACode: Yup.string().length(6, 'Token length must be 6 characters').required('2FA token is required'),
});
