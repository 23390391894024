import { UserCexProfileDto } from "@nestcoinco/onboard-api-gateway-api-client";
import ApiClient, { getTokenHeader } from "./client";

export const authenticateUser = async (email: string, password: string) => {
  const { data } = await ApiClient.exchange.auth.authenticate({
    email: email,
    password: password,
  } as any);

  return data;
};

export const twoFactorAuthenticateUser = async (
  code: string,
  token: string
) => {
  const { data } = await ApiClient.exchange.auth.verify2fa(
    {
      twoFaCode: code,
    },
    getTokenHeader(token)
  );

  return data;
};

export const acceptInvite = async (token: string, password: string) => {
  const { data } = await ApiClient.exchange.user.acceptInvitation(token, {
    password,
  });

  return data;
};

export const generate2faSecretForUser = async (token: string) => {
  const { data } = await ApiClient.exchange.auth.generate2faSecret(
    getTokenHeader(token)
  );

  return data;
};

export const verify2faSecretForUser = async (code: string, token: string) => {
  const { data } = await ApiClient.exchange.auth.verify2fa(
    {
      twoFaCode: code,
    },
    getTokenHeader(token)
  );

  return data;
};

export const getAuthenticatedUser = async (
  token: string
): Promise<UserCexProfileDto> => {
  const { data } = await ApiClient.exchange.user.getAuthUser(
    getTokenHeader(token)
  );

  return data;
};

export const sendPasswordResetLink = async (email: string) => {
  await ApiClient.exchange.auth.getUserPasswordResetToken({
    email,
  });
};

export const resetUserPassword = async (
  request: string,
  password: string
) => {
  await ApiClient.exchange.auth.resetUserPassword({
    password,
    request,
  });
};

export const getPartiallyAuthenticatedUserStatusDetail = async (
  token: string
) => {
  const { data } = await ApiClient.exchange.user.getAuthUserStatuses(
    getTokenHeader(token)
  );

  return data;
};
