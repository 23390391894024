import { useContext } from "react";
import { SignerContext } from "../../providers/SignerProvider";

const useSigner = () => {
  const signerContext = useContext(SignerContext);

  if (!signerContext)
    throw new Error(
      "PROVIDER_ERROR: useSigner can only be used within SignerContext"
    );

  return signerContext;
};

export default useSigner;
