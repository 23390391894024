import { FC } from "react";
import { TableBodyProps as ITableBodyProps, Row } from "react-table";
import classnames from "classnames";

interface TableBodyProps {
  tableBodyProps: ITableBodyProps;
  rows: Row<any>[];
  prepareRow: (row: Row) => void;
}

const TableBody: FC<TableBodyProps> = ({
  tableBodyProps,
  rows,
  prepareRow,
}) => {
  return (
    <tbody {...tableBodyProps}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <tr
            className="border-b hover:bg-blue-50 border-slate-200"
            {...row.getRowProps()}
          >
            {row.cells.map((cell) => {
              return (
                <td
                  className={classnames([
                    "px-6 text-sm font-medium leading-none  text-slate-600 py-7",
                    (cell.column as any)?.className,
                  ])}
                  {...cell.getCellProps([
                    {
                      style: (cell.column as any).style,
                    },
                  ])}
                >
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
