import {
  ApiFullCredentialSet,
  ApiKeyOnly,
  ApiSecretOnly,
  GenerateApiCredentialCommand,
} from '@nestcoinco/onboard-api-gateway-api-client';
import ApiClient, { getTokenHeader } from './client';

export const getApiPubKeys = async (token: string): Promise<ApiKeyOnly[] | undefined> => {
  if (!token) return;
  const { data } = await ApiClient.exchange.apiKey.getAllCredentials(getTokenHeader(token));
  return data.data;
};

export const getApiKeySecret = async (
  token: string,
  credentialId: string,
  totp: string
): Promise<ApiSecretOnly | undefined> => {
  if (!token) return;
  const { data } = await ApiClient.exchange.apiKey.getApiSecretByApiKey(
    credentialId,
    totp,
    getTokenHeader(token)
  );
  return data;
};

export const generateOrRegenerateApiKeys = async (
  token: string,
  input: GenerateApiCredentialCommand
): Promise<ApiFullCredentialSet | undefined> => {
  if (!token) return;
  const { data } = await ApiClient.exchange.apiKey.generateCredentials(
    input,
    getTokenHeader(token)
  );
  return data;
};

export const destroyApiKeys = async (token: string, credentialId: string, totp: string) => {
  if (!token) return;
  await ApiClient.exchange.apiKey.destroyApiCredential(credentialId, totp, getTokenHeader(token));
};
