import { CexSignerDto } from "@nestcoinco/onboard-api-gateway-api-client";
import ApiClient, { getTokenHeader } from "./client";

export const createNewSigner = async (
  networkId: string,
  address: string,
  otp: string,
  token: string
) => {
  await ApiClient.exchange.cex.addSigner(
    {
      address,
      networkId,
      token: otp,
    },
    getTokenHeader(token)
  );
};

export const fetchAllSigners = async (
  token: string,
  networkId?: string
) => {
  let signers: CexSignerDto[];

  if (networkId) {
    const { data } = await ApiClient.exchange.cex.queryNetworkSigner(
      networkId,
      getTokenHeader(token)
    );

    signers = [data];
  } else {
    const { data } = await ApiClient.exchange.cex.querySigners(
      getTokenHeader(token)
    );

    signers = data.signers;
  }

  return signers;
};
