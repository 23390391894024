import { useContext } from 'react';
import { OrderContext } from '../../providers/OrderProvider';

const useOrder = () => {
  const orderContext = useContext(OrderContext);

  if (!orderContext) {
    throw new Error('PROVIDER_ERROR: useOrder can only be used within OrderContext');
  }

  return orderContext;
};

export default useOrder;
