import { AuthLayout, MainLayout } from "../components";
import {
  useAuthenticated,
  useCan2fa,
  useCheckToken,
  useAppAuthenticated,
  useCheckResetRequest,
} from "../middlewares";
import {
  AcceptInvitePage,
  AllLiquidityPools,
  AllSignersPage,
  DashboardPage,
  ForgotPasswordPage,
  LoginPage,
  NewLiquidityPool,
  NewSignerPage,
  ResetPasswordPage,
  TwoFAPage,
  Verify2FAOtpPage,
  SettingsPage,
  ExploreLiquidityPool,
  WithdrawFromLiquidityPool,
  WithdrawalPage,
  ExternalPage,
  OnboardFastPage,
  CompleteExternalWithdrawal,
  ConfirmExternalWithdrawal,
  WithdrawalSuccess,
  ApiKeysPage,
  ConvertFiat,
  CompleteOnboardFastWithdrawal,
  ConfirmOnboardFast,
  WalletPage,
  DepositPage,
  OPNOrdersPage,
  ViewOPNOrderPage,
} from "../pages";
import { RouteMapItemToKey } from "../typings";
import AllOrders from "../pages/Orders/AllOrders";
import TransactionId from "../pages/Wallet/TransactionId";

const routeMap: RouteMapItemToKey = {
  dashboard: {
    path: "/",
    Layout: MainLayout,
    Element: DashboardPage,
    middlewares: [useAppAuthenticated],
  },
  auth: {
    path: "auth",
    Layout: AuthLayout,
    middlewares: [useAuthenticated],
    childRoutes: {
      login: {
        path: "login",
        Element: LoginPage,
        index: true,
      },
      twoFA: {
        path: "2fa",
        Element: TwoFAPage,
        middlewares: [useCan2fa],
      },
      forgotPassword: {
        path: "forgot-password",
        Element: ForgotPasswordPage,
      },
      passwordReset: {
        path: "reset-password",
        Element: ResetPasswordPage,
        middlewares: [useCheckResetRequest],
      },
      acceptInvite: {
        path: "accept-invite",
        Element: AcceptInvitePage,
        middlewares: [useCheckToken],
      },
      twoFAVerify: {
        path: "2fa-setup",
        Element: Verify2FAOtpPage,
      },
    },
  },
  signers: {
    path: "signers",
    Layout: MainLayout,
    middlewares: [useAppAuthenticated],
    childRoutes: {
      allSigners: {
        index: true,
        Element: AllSignersPage,
      },
      newSigner: {
        path: "new",
        Element: NewSignerPage,
      },
    },
  },
  liquidityPools: {
    path: "liquidity-pools",
    Layout: MainLayout,
    middlewares: [useAppAuthenticated],
    childRoutes: {
      allAccounts: {
        index: true,
        Element: AllLiquidityPools,
      },
      newAccount: {
        path: "new",
        Element: NewLiquidityPool,
      },
      liquidityPool: {
        path: ":address",
        Element: ExploreLiquidityPool,
      },
      withdrawal: {
        path: ":address/withdraw",
        Element: WithdrawFromLiquidityPool,
      },
    },
  },
  withdrawal: {
    path: "withdrawal",
    Layout: MainLayout,
    middlewares: [useAppAuthenticated],
    childRoutes: {
      withdrawal: {
        index: true,
        Element: WithdrawalPage,
      },
      external: {
        path: "external",
        childRoutes: {
          external: {
            index: true,
            Element: ExternalPage,
          },
          submit: {
            path: "complete",
            Element: CompleteExternalWithdrawal,
          },
          complete: {
            path: "confirm",
            Element: ConfirmExternalWithdrawal,
          },
        },
      },
      onboardFast: {
        path: "onboard-fast",
        childRoutes: {
          external: {
            index: true,
            Element: OnboardFastPage,
          },
          externalNetwork: {
            path: "convert",
            Element: ConvertFiat,
          },
          submit: {
            path: "complete",
            Element: CompleteOnboardFastWithdrawal,
          },
          complete: {
            path: "confirm",
            Element: ConfirmOnboardFast,
          },
        },
      },
      success: {
        path: "success",
        Element: WithdrawalSuccess,
      },
    },
  },
  orders: {
    path: "orders",
    Layout: MainLayout,
    middlewares: [useAppAuthenticated],
    childRoutes: {
      allOrders: {
        index: true,
        Element: AllOrders,
      },
    },
  },
  exchanges: {
    path: "exchanges",
    Layout: MainLayout,
    middlewares: [useAppAuthenticated],
    childRoutes: {
      allExchanges: {
        index: true,
        Element: OPNOrdersPage,
      },
      exchange: {
        path: "/:orderRef",
        Element: ViewOPNOrderPage,
      },
    },
  },
  apiKeys: {
    path: "api-keys",
    Layout: MainLayout,
    middlewares: [useAppAuthenticated],
    childRoutes: {
      allAccounts: {
        index: true,
        Element: ApiKeysPage,
      },
    },
  },
  settings: {
    path: "settings",
    Layout: MainLayout,
    Element: SettingsPage,
    middlewares: [useAppAuthenticated],
  },
  transactions: {
    path: "wallet",
    Layout: MainLayout,
    middlewares: [useAppAuthenticated],
    childRoutes: {
      allTransactions: {
        index: true,
        Element: WalletPage,
      },
      transactionId: {
        path: "/transactions/:transactionId",
        Element: TransactionId,
      },
    },
  },
  deposit: {
    path: "deposit",
    Layout: MainLayout,
    Element: DepositPage,
    middlewares: [useAppAuthenticated],
  },
};

export default routeMap;
