import { CexSignerDto } from '@nestcoinco/onboard-api-gateway-api-client';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { getErrorMessage } from '../../api/error-handler';
import { createNewSigner, fetchAllSigners } from '../../api/signer';
import { useAuth } from '../../hooks';
import { ContextActionReturnType } from '../../typings/interfaces/context';

interface SignerContextProps {
  loading: boolean;
  isFetching: boolean;
  create: (
    networkId: string,
    address: string,
    otp: string
  ) => Promise<ContextActionReturnType | void>;
  signers: CexSignerDto[] | undefined;
  fetchAll: (networkId?: string) => Promise<void>;
}

export const SignerContext = createContext<SignerContextProps | null>(null);

const SignerProvider = (prop: any) => {
  const { authToken, verified } = useAuth();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState<boolean>(false);
  const [signers, setSigners] = useState<CexSignerDto[] | undefined>();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const handleError = useCallback(
    (e: any) => {
      const error = getErrorMessage(e).message;
      addToast(error, { appearance: 'error' });
    },
    [addToast]
  );

  const fetchAll = useCallback(
    async (networkId?: string) => {
      setLoading(true);
      fetchAllSigners(authToken!, networkId)
        .then(setSigners)
        .catch(handleError)
        .finally(() => setLoading(false));
    },
    [authToken, handleError]
  );

  const create = useCallback(
    async (networkId: string, address: string, otp: string) => {
      setLoading(true);
      try {
        if (!authToken) {
          return { error: 'Authentication session not found!' };
        }
        await createNewSigner(networkId, address, otp, authToken!);
        fetchAll();
      } catch (e) {
        return { error:  getErrorMessage(e).message, errorData: getErrorMessage(e).data };
      } finally {
        setLoading(false);
      }
    },
    [authToken, fetchAll]
  );

  useEffect(() => {
    if (!authToken || !verified) return;
    setIsFetching(true);
    fetchAllSigners(authToken!)
      .then(setSigners)
      .catch(handleError)
      .finally(() => setIsFetching(false));
  }, [authToken, handleError, verified]);

  const values = useMemo<SignerContextProps>(
    () => ({
      loading,
      create,
      signers,
      fetchAll,
      isFetching,
    }),
    [loading, create, signers, fetchAll, isFetching]
  );

  return <SignerContext.Provider value={values}>{prop.children}</SignerContext.Provider>;
};

export default SignerProvider;
