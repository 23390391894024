import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks";
import { AuthType } from "../providers/AuthProvider";

const useCan2fa = () => {
  const { authType, authToken, storageLoaded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!storageLoaded) return;
    const can2fa = authType === AuthType.PARTIAL && authToken;

    if (!can2fa) return navigate("/auth/login");
  }, [authToken, authType, storageLoaded, navigate]);

  return true;
};

export default useCan2fa;
