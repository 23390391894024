import React, { PropsWithChildren } from 'react';

interface ITabNavList {
  id: string;
}

const TabNavList: React.FC<PropsWithChildren<ITabNavList>> = ({ children, id }) => {
  return (
    <ul
      id={id}
      className='nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4'
      role='tablist'>
      {children}
    </ul>
  );
};

export default TabNavList;
