import { TokenNetwork } from "@nestcoinco/onboard-api-gateway-api-client";
import React, { Reducer, useContext, useReducer } from "react";
import DepositAddress from "./components/DepositAddress";
import DepositMethod from "./components/DepositMethod";
import SelectStableCoin from "./components/SelectStableCoin";
import SelectTokenNetwork from "./components/SelectTokenNetwork";

interface ISelectedToken {
  name: string;
  networks: TokenNetwork[];
}

type DepositAction =
  | { selectedToken: ISelectedToken }
  | { selectedNetwork: TokenNetwork }
  | { step: string }
  | { depositAddress: string };

interface IState {
  selectedToken: ISelectedToken | null;
  selectedNetwork: TokenNetwork | null;
  step: string;
  depositAddress: string | null;
}

interface IDepositContext {
  state: IState;
  dispatch: (action: DepositAction) => void;
}

export const DepositContext = React.createContext<IDepositContext | null>(null);

const depositReducer = (state: IState, action: DepositAction): IState => {
  return {
    ...state,
    ...action,
  };
};

const DepositPage = () => {
  const [state, dispatch] = useReducer<Reducer<IState, DepositAction>>(
    depositReducer,
    {
      selectedToken: null,
      selectedNetwork: null,
      step: "method",
      depositAddress: null,
    }
  );
  const Component = componentsMap[state.step];

  return (
    <DepositContext.Provider value={{ state, dispatch }}>
      <Component />
    </DepositContext.Provider>
  );
};

export function useDepositContext() {
  const context = useContext(DepositContext);
  if (!context)
    throw new Error("useDepositContext cannot be used outside DepositContext");

  return context;
}

const componentsMap: any = {
  method: DepositMethod,
  network: SelectTokenNetwork,
  coin: SelectStableCoin,
  address: DepositAddress,
};

export default DepositPage;
