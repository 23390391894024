import UrlBox from '../UrlBox';
import useUrls from '../../../../../hooks/useUrls';
import { Card, TooltipWithCTA } from '../../../../../components';

const TradeExecutionUrl = () => {
  const { autoExecUrl, setAutoExecUrl, upsertAutoExecUrl } = useUrls();

  return (
    <Card title='Execution Url' classNames='mt-7'>
      <div className='text-[14px] mt-3'>
        Update{' '}
        <TooltipWithCTA title='Notification to execution trade will be sent to this URL.'>
          execution url
        </TooltipWithCTA>{' '}
        here
      </div>
      <UrlBox
        url={autoExecUrl}
        inputPlaceholder='Webhook URL'
        updateStateUrl={setAutoExecUrl}
        submitContextHandler={upsertAutoExecUrl}
      />
    </Card>
  );
};

export default TradeExecutionUrl;
