import { CheckmarkOutline } from "react-ionicons";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "../../components";
import PageWrapper from "../../components/PageWrapper";

const WithdrawalSuccessful = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state: any = location.state;
  const viewWallet = () => {
    navigate("/wallet", {
      replace: true,
    });
  };
  if (!state?.message) {
    viewWallet();
  }

  return (
    <PageWrapper title="">
      <div className="flex justify-center w-full">
        <Card
          title=""
          headingClassNames="mb-8 text-center"
          classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
          children={
            <div className="flex flex-col items-center">
              <CheckmarkOutline />
              <p>{state.message}</p>
              <p
                className="mt-4 underline text-blue-700 cursor-pointer"
                onClick={viewWallet}
              >
                View Wallet
              </p>
            </div>
          }
        />
      </div>
    </PageWrapper>
  );
};

export default WithdrawalSuccessful;
