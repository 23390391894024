import { ArrowBackOutline } from "react-ionicons";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "../../components";
import PageWrapper from "../../components/PageWrapper";


const Withdrawal = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <PageWrapper title="Withdrawal">
      <p className="mt-4 flex cursor-pointer" onClick={handleBack}><ArrowBackOutline /> Back </p>
      <div className="flex justify-center w-full">
        <Card
          title="Select withdrawal method"
          headingClassNames="mb-8 text-center"
          classNames="py-12 px-6 mt-20 min-w-[400px] max-w-[500px] justify-center bg-white shadow-md rounded-md"
          children={
            <>
              <Button
                className="w-full border text-center py-6 my-6 rounded-md hover:bg-gray-100"
                text={"To external address (stablecoin)"}
                variant="text"
                onClick={() => {
                  navigate("external")
                }}
              />
              <Button
                className="w-full border text-center py-6 my-6 rounded-md hover:bg-gray-100"
                text={"Onboard Fast (Withdraw to Local currency)"}
                variant="text"
                onClick={() => {
                  navigate("onboard-fast")
                }}
              />
              <Button
                className="w-full border text-center py-6 my-6 rounded-md hover:bg-gray-100"
                text={"To USD account (coming soon)"}
                variant="text"
                disabled
              />
            </>
          }
        />
      </div>
    </PageWrapper>
  );
};

export default Withdrawal;
