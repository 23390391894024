const AvaterIcon = () => (
  <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.0003 17.5C16.0379 17.5 18.5003 15.0376 18.5003 12C18.5003 8.96243 16.0379 6.5 13.0003 6.5C9.96274 6.5 7.50031 8.96243 7.50031 12C7.50031 15.0376 9.96274 17.5 13.0003 17.5Z'
      fill='#351794'></path>
    <path
      d='M13 0C10.4288 0 7.91543 0.762437 5.77759 2.19089C3.63975 3.61935 1.97351 5.64967 0.989572 8.02511C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5994 25.9944 17.9749 25.0104C20.3503 24.0265 22.3806 22.3602 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9934 9.55421 24.6216 6.25145 22.1851 3.81491C19.7485 1.37837 16.4458 0.00660747 13 0ZM21.225 20.3C20.4155 19.1388 19.3724 18.1596 18.1625 17.425C16.7733 18.7559 14.9238 19.4988 13 19.4988C11.0762 19.4988 9.22669 18.7559 7.8375 17.425C6.62759 18.1596 5.58448 19.1388 4.775 20.3C3.36722 18.7147 2.44757 16.7564 2.12671 14.6607C1.80585 12.565 2.09746 10.4212 2.96644 8.48731C3.83543 6.55345 5.24477 4.91192 7.02484 3.76027C8.80491 2.60863 10.8799 1.99593 13 1.99593C15.1201 1.99593 17.1951 2.60863 18.9752 3.76027C20.7552 4.91192 22.1646 6.55345 23.0336 8.48731C23.9025 10.4212 24.1942 12.565 23.8733 14.6607C23.5524 16.7564 22.6328 18.7147 21.225 20.3Z'
      fill='#5433FF'></path>
  </svg>
);

export default AvaterIcon;
