import React, { PropsWithChildren, SetStateAction } from 'react';
import classnames from 'classnames';
import MenuIcon from '../Icons/MenuIcon';

type ITableActionMenu = React.FC<PropsWithChildren<{
  row: any,
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>
}>>

const TableActionMenu: ITableActionMenu = ({ row, children, setIsOpen, isOpen }) => {
  return (
    <div
      onMouseLeave={() => setIsOpen(false)}
      className='relative flex justify-center justify-self-center actions-intereaction cursor-pointer'
      onClick={() => setIsOpen((v) => !v)}>
      <MenuIcon />
      <div
        className={classnames([
          'px-1 absolute top-4 right-0 py-2 rounded shadow-md w-[124px] z-10 bg-white',
          {
            hidden: !isOpen,
          },
        ])}>
          {children}
      </div>
    </div>
  );
};

export default TableActionMenu;
