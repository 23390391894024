import {
  Input,
  Select,
  Option,
  Button as TWButton,
  Alert,
} from '@material-tailwind/react';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowBackOutline, InformationCircleOutline, OpenOutline } from 'react-ionicons';
import { useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { getErrorMessage } from '../../../api';
import { FormikError, TooltipWithCTA } from '../../../components';
import Button from '../../../components/Button';
import PageWrapper from '../../../components/PageWrapper';
import { useConfig } from '../../../hooks';
import useAccount from '../../../hooks/useAccounts';
import { WithdrawaAseetRequest } from '../../../providers/AccountProvider';
import { withdrawalValidationSchema } from '../../../schemas/accounts';

const Withdrawal = () => {
  const { address } = useParams();
  const { networks } = useConfig();
  const [searchParams] = useSearchParams();

  const { accounts, returnPoolBalances, fetchPoolBalances, withdrawAsset } = useAccount();
  const assetSymbol = useMemo(() => searchParams.get('asset') ?? '', [searchParams]);
  const networkId = useMemo(() => searchParams.get('networkId'), [searchParams]);
  const [selectedAsset, setSelectedAsset] = useState<string | undefined>(assetSymbol);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [previewHash, setPreviewHash] = useState<string>('');

  const pool = useMemo(() => {
    if (!accounts || !address) return;
    return (accounts ?? []).find((a) => address.toLowerCase() === a?.address?.toLowerCase());
  }, [accounts, address]);

  const network = useMemo(
    () => networks?.find((n) => n.networkId === networkId),
    [networkId, networks]
  );

  const initialValues: WithdrawaAseetRequest = useMemo(
    () => ({
      poolAddress: address ?? '',
      asset: assetSymbol ?? '',
      networkId: networkId ?? '',
      amount: '',
    }),
    [address, assetSymbol, networkId]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: withdrawalValidationSchema,
    onSubmit: async (values) => {
      const response = await withdrawAsset(values);
      if (response?.error) {
        const error = getErrorMessage(response?.error);
        addToast(error.message, { appearance: 'error' });
      } else {
        await fetchPoolBalances(values.poolAddress, values.networkId);
        setAlertState(true, response?.data?.transactionHash);
        formik.resetForm();
      }
    },
  });

  const balances = useMemo(() => {
    if (pool?.address) return returnPoolBalances(pool.address, pool.networkId);
    return [];
  }, [pool?.address, pool?.networkId, returnPoolBalances]);

  const balance = useMemo(() => {
    const bal = balances.find((b) => b.assetSymbol === selectedAsset);
    if (!bal) return null;
    return bal.balance;
  }, [balances, selectedAsset]);

  const handleAsstChange = (val: any) => {
    setSelectedAsset(val);
    formik.setFieldValue('asset', val, true);
  };

  const setAlertState = (state: boolean, hash: string = '') => {
    setPreviewHash(hash);
    setShowAlert(state);
  };

  const handleAmountChange = useCallback(
    (e: any) => {
      const amount = +(e?.target?.value ?? 0);
      if (amount > +(balance ?? 0)) {
        formik.setFieldValue('amount', amount, false);
        formik.setFieldError('amount', 'Amount is greater than available balance');
      } else {
        formik.handleChange(e);
      }
    },
    [balance, formik]
  );

  const handleSetMax = () => {
    if (!isNaN(parseFloat(`${balance}`))) {
      formik.setFieldValue('amount', balance, true);
    }
  };

  useEffect(() => {
    if (!pool) return;
    fetchPoolBalances(pool?.address!, pool.networkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool]);

  return (
    <PageWrapper title='Withdrawal'>
      <div className='py-[4rem]'>
        <form
          onSubmit={formik.handleSubmit}
          className='mx-auto max-w-3xl bg-white rounded shadow py-7'>
          <div className='mt-8 px-7 flex flex-col gap-8'>
            <div className='flex justify-between'>
              <Link to={`/liquidity-pools/${address}?networkId=${networkId}`}>
                <ArrowBackOutline cssClasses='cursor-pointer' />
              </Link>
            </div>
            <div>
              <Alert
                color='green'
                show={showAlert}
                dismissible={{
                  onClose: () => setAlertState(false),
                }}
                variant='filled'
                icon={<InformationCircleOutline color='inherit' />}>
                <div className='flex flex-row gap-3'>
                  <p>Withdrawal completed successfully.</p>
                  <TooltipWithCTA title='View on explorer'>
                    <a
                      href={`${network?.explorer}/tx/${previewHash}`}
                      target='_blank'
                      rel='noreferrer'>
                      <OpenOutline color='white' />
                    </a>
                  </TooltipWithCTA>
                </div>
              </Alert>
            </div>
            <div className='flex flex-col text-sm font-bold gap-2'>
              {balance && selectedAsset && (
                <p className='text-green-500 text-right'>Balance: {`${balance}${selectedAsset}`}</p>
              )}
              <Select
                label='Select asset'
                value={selectedAsset}
                onChange={(e) => handleAsstChange(e)}
                size='lg'
                color='gray'>
                {balances.map((bal, index) => (
                  <Option value={bal.assetSymbol} key={index}>
                    {bal.assetSymbol}
                  </Option>
                ))}
              </Select>
              <FormikError error={formik.errors.asset} />
            </div>
            <div className='flex flex-col gap-2'>
              <Input
                name='amount'
                required
                value={formik.values.amount}
                onChange={handleAmountChange}
                color='gray'
                variant='outlined'
                label='Enter amount'
                type='number'
                size='lg'
                icon={
                  <TWButton
                    className='-ml-5 text-black-500'
                    variant='text'
                    size='sm'
                    onClick={() => handleSetMax()}>
                    ALL
                  </TWButton>
                }
                max={balance ?? 0}
              />
              <FormikError error={formik.errors.amount} />
            </div>
            <div className='flex flex-col gap-2'>
              <Input
                label='Network'
                type='text'
                size='lg'
                value={network ? `${network.name} (${network.symbol})` : ''}
                color='gray'
                readOnly
              />
              <input
                name='networkId'
                value={formik.values.networkId}
                onChange={formik.handleChange}
                hidden
              />
              <FormikError error={formik.errors.networkId} />
            </div>
            <div className='flex flex-col gap-2'>
              <Input
                name='poolAddress'
                label='From'
                type='text'
                size='lg'
                onChange={formik.handleChange}
                value={formik.values.poolAddress}
                color='gray'
                readOnly
              />
              <FormikError error={formik.errors.poolAddress} />
            </div>

            <Input
              label='Recipient'
              type='text'
              size='lg'
              value={pool?.cexSigner || ''}
              color='gray'
              readOnly
            />
          </div>
          <hr className='bg-slate-100 h-[1px] w-full mt-10' />
          <div className='px-7 flex flex-wrap items-center lg:justify-end md:justify-end justify-center gap-4 pt-10'>
            <Button
              text='Withdraw'
              type='submit'
              disabled={!formik.dirty || !formik.isValid}
              loading={formik.isSubmitting}
            />
          </div>
        </form>
      </div>
    </PageWrapper>
  );
};

export default Withdrawal;
function addToast(error: any, arg1: { appearance: string }) {
  throw new Error('Function not implemented.');
}
