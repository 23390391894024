import Input from '../Input';
import Button from '../Button';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { twoFAVerificationSchema } from '../../schemas/auth';
import { CloseCircleOutline } from 'react-ionicons';

interface IFormProps {
  twoFACode: string;
}

interface ITwoFaModal {
  show: boolean;
  loading?: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  handleSubmit?: (totp: string) => void;
}

const TwoFaModal: React.FC<ITwoFaModal> = ({ show, setShow, loading, handleSubmit }) => {
  const initialValues: IFormProps = { twoFACode: '' };

  const handleClose = () => {
    setShow(false);
  };

  const triggerSubmit = ({ twoFACode }: IFormProps) => {
    handleSubmit && handleSubmit(twoFACode);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: twoFAVerificationSchema,
    onSubmit: (values) => triggerSubmit(values),
  });

  useEffect(() => {
    formik.setFieldValue('twoFACode', '', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return show ? (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className='h-screen w-screen backdrop-blur-md bg-black-200/30 place-items-center z-100 fixed grid -top-0 right-0 bottom-0'>
      <div className='inset-0 absolute' />
      <motion.article
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.35 }}
        exit={{ scale: 0 }}
        className='bg-white rounded-sm border border-grey flex flex-col items-center w-11/12 sm:w-3/4 max-w-[450px] pt-16 pb-9 relative px-5 shadow-lg'>
        <button
          className='appearance-none w-auto h-auto absolute top-6 right-6'
          onClick={handleClose}
          autoFocus>
          <CloseCircleOutline />
        </button>
        <div className='mt-4 w-full max-w-[300px]'>
          <h2 className='justify-center text-13 md:text-14 w-full text-center mx-auto mt-1'>
            Enter 2FA Token
          </h2>
          <div className='w-full mt-8 mb-5'>
            <Input
              required
              type='text'
              name='twoFACode'
              value={formik.values.twoFACode}
              onChange={formik.handleChange}
              error={formik.errors.twoFACode}
              className='text-center'
            />
          </div>
          <div className='w-full'>
            <Button
              type='button'
              text='Submit'
              fullWidth
              onClick={() => formik.handleSubmit()}
              loading={loading ?? formik.isSubmitting}
              disabled={!formik.isValid || !formik.dirty}
            />
          </div>
        </div>
      </motion.article>
    </motion.main>
  ) : null;
};

export default TwoFaModal;
