import PageWrapper from '../../../components/PageWrapper';
import { Button, SelectNetwork, Table } from '../../../components';
import { AddOutline } from 'react-ionicons';
import { useEffect, useMemo } from 'react';
import { allSignersTableColumn } from '../../../constants/signers';
import { Link } from 'react-router-dom';
import MenuActionCol from './components/MenuActionCol';
import { useConfig, useSigner } from '../../../hooks';

const AllSigners = () => {
  const { networks } = useConfig();
  const { loading, isFetching, signers, fetchAll } = useSigner();

  const columns = useMemo(() => {
    if (!networks?.length) return [];
    return [
      ...allSignersTableColumn(networks),
      {
        Header: 'Actions',
        accessor: 'actions',
        className: 'text-center',
        Cell: MenuActionCol,
      },
    ];
  }, [networks]);

  const handleNetworkChange = (networkId: string) => {
    fetchAll(networkId === 'all' ? undefined : networkId);
  };

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  return (
    <PageWrapper title='Signers'>
      <div className='mt-7'>
        <div className='flex justify-end my-6 gap-5'>
          <div className='w-72'>
            <SelectNetwork
              value='all'
              variant='outlined'
              label='Select network'
              allowAllFilter
              onChange={(v) => handleNetworkChange(v!.toString())}
              size='lg'
            />
          </div>
          <div>
            <Link to='/signers/new'>
              <Button
                text='Add address'
                className='py-[10px]'
                icon={<AddOutline color='inherit' />}
                fullWidth
              />
            </Link>
          </div>
        </div>
        <Table
          loading={loading || isFetching}
          columns={columns}
          data={signers ?? []}
          noResultMessage='No wallet signer found. Add new address.'
        />
      </div>
    </PageWrapper>
  );
};

export default AllSigners;
