import React, { PropsWithChildren } from 'react';

interface ITabContent {
  id: string;
}

const TabContent: React.FC<PropsWithChildren<ITabContent>> = ({ id, children }) => (
  <div className='tab-content' id={id}>
    {children}
  </div>
);

export default TabContent;
