import { NetworkDto } from '@nestcoinco/onboard-api-gateway-api-client';
import { motion } from 'framer-motion';
import React, { SetStateAction } from 'react';
import { CloseCircleOutline } from 'react-ionicons';
import { QRCodeSVG } from 'qrcode.react';
import { Copier } from '../../../../../components';

interface IProps {
  show: boolean;
  address: string;
  network: NetworkDto;
  toggleShow: React.Dispatch<SetStateAction<boolean>>;
}

const DepositModal: React.FC<IProps> = ({ show, toggleShow, address, network }) => {
  return show ? (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className='h-screen w-screen backdrop-blur-md bg-black-200/30 place-items-center z-100 fixed grid -top-0 right-0 bottom-0'>
      <div className='inset-0 absolute' />
      <motion.article
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.35 }}
        exit={{ scale: 0 }}
        className='bg-white rounded-sm border border-grey flex flex-col items-center w-11/12 sm:w-3/4 max-w-[500px] pt-5 pb-5 relative px-5 shadow-lg'>
        <button
          className='appearance-none w-auto h-auto absolute top-6 right-6'
          onClick={() => toggleShow(false)}
          autoFocus>
          <CloseCircleOutline />
        </button>
        <div className='mt-4 w-full max-w-[400px]'>
          <h2 className='justify-center text-13 md:text-14 w-full text-center mx-auto'>
            Asset Deposit
          </h2>
          <div className='w-full flex justify-center my-6'>
            <QRCodeSVG value={address} size={150} />
          </div>
          <div className='w-full flex justify-center gap-3'>
            <p className='text-sm'>{address}</p>
            <Copier text={address} />
          </div>
          <div className='bg-gray-200 rounded-md p-4 my-3'>
            <div className='w-full flex justify-center gap-3'>
              <p className='font-bold'>Network:</p>
              <p className=''>{`${network.name} (${network.symbol})`}</p>
            </div>
            <p className='py-3 text-xs text-center text-yellow-900'>{`Send only (${network.symbol}) supported asset to this address to avoid permanent loss of fund.`}</p>
          </div>
        </div>
      </motion.article>
    </motion.main>
  ) : null;
};

export default DepositModal;
