import {
  CexProfileDto,
  UserCexProfileDto,
} from "@nestcoinco/onboard-api-gateway-api-client";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useToasts } from "react-toast-notifications";
import {
  acceptInvite,
  authenticateUser,
  getAuthenticatedUser,
  resetUserPassword,
  sendPasswordResetLink,
  twoFactorAuthenticateUser,
  verify2faSecretForUser,
} from "../../api/auth";
import { getErrorMessage } from "../../api/error-handler";
import { authForbidden } from "../../constants/events";
import {
  getInitialStorageState,
  updateStorageState,
} from "../../lib/localstorage";
import { ContextActionReturnType } from "../../typings/interfaces/context";

export enum AuthType {
  PARTIAL = "PARTIAL",
  FULL = "FULL",
}

interface AuthContextProps {
  verified: boolean;
  authType?: AuthType;
  authToken?: string;
  loading: boolean;
  storageLoaded: boolean;
  user?: UserCexProfileDto;
  business?: CexProfileDto;
  authenticate: (
    email: string,
    password: string
  ) => Promise<ContextActionReturnType | void>;
  twoFactorAuthenticate: (
    code: string
  ) => Promise<ContextActionReturnType | void>;
  acceptInvitation: (
    token: string,
    password: string
  ) => Promise<ContextActionReturnType | void>;
  verify2faSecret: (code: string) => Promise<ContextActionReturnType | void>;
  requestPasswordReset: (
    email: string
  ) => Promise<ContextActionReturnType | void>;
  resetPassword: (
    request: string,
    password: string
  ) => Promise<ContextActionReturnType | void>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

const AuthProvider = (prop: any) => {
  const [verified, setVerified] = useState(false);
  const [authType, setAuthType] = useState<AuthType>();
  const [authToken, setAuthToken] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserCexProfileDto>();
  const [localStateLoaded, setLocalStateLoaded] = useState(false);
  const [business, setBusiness] = useState<CexProfileDto>();

  const { addToast } = useToasts();

  const handleAuthUserFetchError = useCallback(
    (e: any) => {
      const error = getErrorMessage(e).message;
      addToast(error, { appearance: "error" });
    },
    [addToast]
  );

  useEffect(() => {
    const { verified, authType, authToken, user } =
      getInitialStorageState<AuthContextProps>(AuthProvider.name);
    setVerified(verified);
    setAuthType(authType);
    setAuthToken(authToken);
    setUser(user);
    setLocalStateLoaded(true);
  }, []);

  useEffect(() => {
    if (!localStateLoaded) return;
    updateStorageState(AuthProvider.name, {
      verified,
      authType,
      authToken,
      user,
    });
  }, [verified, authType, authToken, user, localStateLoaded]);

  useEffect(() => {
    if (!localStateLoaded) return;
    if (verified && authType === AuthType.FULL && authToken) {
      setLoading(true);
      getAuthenticatedUser(authToken)
        .then((data) => {
          setUser(data);
          setBusiness(data.business);
        })
        .catch(handleAuthUserFetchError)
        .finally(() => setLoading(false));
    }
  }, [
    localStateLoaded,
    verified,
    authType,
    authToken,
    handleAuthUserFetchError,
  ]);

  useEffect(() => {
    window.addEventListener(authForbidden, () => logout());

    return () => {
      window.removeEventListener(authForbidden, () => logout());
    };
  }, []);

  const authenticate = async (email: string, password: string) => {
    setLoading(true);
    try {
      const { accessToken, verified } = await authenticateUser(email, password);
      setVerified(verified);
      setAuthType(AuthType.PARTIAL);
      setAuthToken(accessToken);
    } catch (e: any) {
      return { error: getErrorMessage(e) };
    } finally {
      setLoading(false);
    }
  };

  const twoFactorAuthenticate = useCallback(
    async (code: string) => {
      setLoading(true);
      try {
        const { accessToken, verified } = await twoFactorAuthenticateUser(
          code,
          authToken!
        );
        setVerified(verified);
        setAuthType(AuthType.FULL);
        setAuthToken(accessToken);
      } catch (e) {
        return { error: getErrorMessage(e) };
      } finally {
        setLoading(false);
      }
    },
    [authToken]
  );

  const acceptInvitation = async (token: string, password: string) => {
    setLoading(true);
    try {
      const { accessToken, verified } = await acceptInvite(token, password);
      setVerified(verified);
      setAuthType(AuthType.PARTIAL);
      setAuthToken(accessToken);
    } catch (e) {
      return { error: getErrorMessage(e) };
    } finally {
      setLoading(false);
    }
  };

  const verify2faSecret = useCallback(
    async (code: string) => {
      setLoading(true);
      try {
        const { accessToken, verified } = await verify2faSecretForUser(
          code,
          authToken!
        );
        setVerified(verified);
        setAuthType(AuthType.FULL);
        setAuthToken(accessToken);
      } catch (e) {
        return { error: getErrorMessage(e) };
      } finally {
        setLoading(false);
      }
    },
    [authToken]
  );

  const requestPasswordReset = async (email: string) => {
    setLoading(true);
    try {
      await sendPasswordResetLink(email);
      setVerified(false);
      setAuthType(undefined);
      setAuthToken(undefined);
    } catch (e) {
      return { error: getErrorMessage(e) };
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (request: string, password: string) => {
    setLoading(true);
    try {
      await resetUserPassword(request, password);
      setVerified(false);
      setAuthType(undefined);
      setAuthToken(undefined);
    } catch (e) {
      return { error: getErrorMessage(e) };
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setVerified(false);
    setAuthType(undefined);
    setAuthToken(undefined);
    setUser(undefined);
  };

  const values = useMemo<AuthContextProps>(
    () => ({
      verified,
      authType,
      authToken,
      loading,
      storageLoaded: localStateLoaded,
      user,
      business,
      authenticate,
      twoFactorAuthenticate,
      acceptInvitation,
      verify2faSecret,
      requestPasswordReset,
      resetPassword,
      logout,
    }),
    [
      verified,
      authType,
      authToken,
      loading,
      localStateLoaded,
      user,
      business,
      twoFactorAuthenticate,
      verify2faSecret,
    ]
  );

  return (
    <AuthContext.Provider value={values}>{prop.children}</AuthContext.Provider>
  );
};

export default AuthProvider;
