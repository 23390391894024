import { LedgerTransaction } from "@nestcoinco/onboard-api-gateway-api-client";
import { useCallback, useEffect, useState } from "react";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { toCommaValue } from "../../lib";
import BackButton from "../../components/Button/BackButton";
import PageWrapper from "../../components/PageWrapper";
import Order from "./OrderDetails";
import { getValueFromMetadata } from "../../lib/transactions";
import { getErrorMessage } from "../../api";
import { useToasts } from "react-toast-notifications";
import { useAuth } from "../../hooks";
import { Loader } from "../../components";
import { getSingleTransaction } from "../../api/transactions";
import { useLocation } from "react-router-dom";
import { toPositiveValue } from "../../lib/toPositiveValue";
import { DEBIT } from "../../constants/transaction";
dayjs.extend(relativeTime);

function TransactionId() {
  const [orderId, setOrderId] = useState<string | null>(null);
  const [transaction, setTransaction] = useState<LedgerTransaction | null>(
    null
  );
  const tokenArray = transaction?.blockchainInfo?.tokenSymbol?.split("_");
  const tokenName = tokenArray?.[0];
  const tokenNetwork = tokenArray?.[1];
  const {
    state: { referenceId },
  } = useLocation();
  const { authToken, verified } = useAuth();
  const { addToast } = useToasts();
  const amount = transaction?.feeInclusive
    ? transaction?.amount! + transaction?.feeAmount!
    : transaction?.amount;
  const rate = getValueFromMetadata("rate", transaction?.metadata!);
  const [isLoading, setIsLoading] = useState(false);

  const handleError = useCallback(
    (e: any) => {
      const error = getErrorMessage(e);
      addToast(error.message, { appearance: "error" });
    },
    [addToast]
  );

  useEffect(() => {
    if (!authToken || !verified) return;
    setIsLoading(true);
    getSingleTransaction(authToken, referenceId)
      .then(setTransaction)
      .catch(handleError)
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError, authToken, verified]);

  return (
    <PageWrapper title="Wallet Transaction">
      <div className="max-w-[500px] mx-auto pb-8">
        {orderId ? (
          <Order orderId={orderId} setOrderId={setOrderId} />
        ) : isLoading ? (
          <div className="py-4">
            <Loader color="blue-600" />
          </div>
        ) : (
          <>
            <div className="mb-4 mt-16">
              <BackButton />
            </div>
            <div className="border border-gray-100 rounded-md p-6 bg-white">
              <h1 className="font-bold text-xl mb-6">Transaction Details</h1>

              <ul>
                <li className="flex gap-6 py-2">
                  <span className="font-semibold basis-40 inline-block">
                    Date
                  </span>
                  {dayjs().to(dayjs(Number(transaction?.createdAt!) * 1000))}
                </li>
                <li className="flex gap-6 py-2">
                  <span className="font-semibold basis-40 inline-block">
                    Amount
                  </span>
                  {tokenName || transaction?.assetCode}{" "}
                  {toPositiveValue(toCommaValue(amount!))}
                </li>
                <li className="flex gap-6 py-2">
                  <span className="font-semibold basis-40 inline-block">
                    Transaction Type
                  </span>
                  {transaction?.type?.split("_")[1]}
                </li>
                <li className="flex gap-6 py-2">
                  <span className="font-semibold basis-40 inline-block">
                    Description
                  </span>
                  {transaction?.notes}
                </li>
              </ul>
              {rate ? (
                <>
                  <h2 className="font-semibold text-lg mt-6">
                    Exchange Details
                  </h2>
                  <ul>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Rate
                      </span>
                      {getValueFromMetadata(
                        "localCurrency",
                        transaction?.metadata!
                      )}{" "}
                      {getValueFromMetadata("rate", transaction?.metadata!)}
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Total Amount
                      </span>
                      {getValueFromMetadata(
                        "localCurrency",
                        transaction?.metadata!
                      )}{" "}
                      {toPositiveValue(
                        Number(
                          getValueFromMetadata("rate", transaction?.metadata!)
                        ) * amount!
                      ) || "-"}
                    </li>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Account
                      </span>
                      {getValueFromMetadata(
                        "accountName",
                        transaction?.metadata!
                      )}
                    </li>
                    <li
                      onClick={() =>
                        setOrderId(transaction?.reference?.replace("OPN-", "")!)
                      }
                      className="text-center py-6 text-primary underline underline-offset-4 cursor-pointer"
                    >
                      View order details
                    </li>
                  </ul>{" "}
                </>
              ) : null}
              {transaction?.blockchainInfo ? (
                <>
                  <h2 className="font-bold text-lg my-6">Blockchain Details</h2>
                  <ul>
                    <li className="flex gap-6 py-2">
                      <span className="font-semibold basis-40 inline-block">
                        Token
                      </span>
                      {tokenName}
                    </li>
                    <li className="grid grid-cols-8 gap-6 py-2">
                      <span className="font-semibold col-start-1 col-end-4">
                        Recipent Address
                      </span>
                      <span className="break-words col-start-4 col-end-9">
                        {transaction?.blockchainInfo?.recipient.address}
                      </span>
                    </li>
                    <li className="grid grid-cols-8 gap-6 py-2">
                      <span className="font-semibold col-start-1 col-end-4">
                        Network
                      </span>
                      <span className="break-words col-start-4 col-end-9">
                        {tokenNetwork}
                      </span>
                    </li>
                  </ul>
                </>
              ) : null}
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
}

export default TransactionId;
