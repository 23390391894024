import useOrder from "../../hooks/useOrder";
import { useEffect, useMemo, useState } from "react";
import {
  OrderQueryStatus,
  OrderResponse,
} from "@nestcoinco/onboard-api-gateway-api-client";
import PageWrapper from "../../components/PageWrapper";
import { Table } from "../../components";
import { opnOrderColumn } from "../../constants/order";
import { IOPNOrderFilter } from "../../api/order";
import FilterSelect from "../../components/Select";
import { useNavigate } from "react-router-dom";

const Exchanges = () => {
  const navigate = useNavigate()
  const DEFAULT_PAGE_SIZE = 10;
  const [bufferFilter, setBufferFilter] = useState<Partial<IOPNOrderFilter>>({
    size: DEFAULT_PAGE_SIZE,
  });

  const [orders, setOrders] = useState<OrderResponse[]>([]);
  const [more, setMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const { fetchOPNOrders } = useOrder();

  const columns = useMemo(() => opnOrderColumn(navigate!), []);

  useEffect(() => {
    setLoading(true)
    fetchOPNOrders(bufferFilter)
      .then(
        (orderResponse) => {
          if (orderResponse?.data) {
            const { data } = orderResponse;
            setOrders(data?.records!);
            setMore(!!data?.nextPage);
          } else {
            setOrders([]);
          }
        }
      )
      .catch((e) => {
        setOrders([]);
      }).finally(() => {
        setLoading(false)
      });
  }, [bufferFilter]);

  const handleStatusChange = (sf: OrderQueryStatus) => {
    setBufferFilter({ ...bufferFilter, status: sf });
  };

  return (
    <PageWrapper title="Exchanges">
      <div className="mt-12">
        <div className="mt-7">
          <div className="flex justify-end my-6 gap-5">
            <div className="w-72">
              <FilterSelect
                value="all"
                variant="outlined"
                label="Select Status"
                allowAllFilter
                size="lg"
                onChange={(v) => handleStatusChange(v!.toString() as OrderQueryStatus)}
                filterOptions={[
                  { label: 'Completed', value: OrderQueryStatus.COMPLETED},
                  { label: 'Failed', value: OrderQueryStatus.FAILED},
                  { label: 'Ongoing', value: OrderQueryStatus.ONGOING},
                ]}
              />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          data={orders}
          loading={loading}
          noResultMessage="No orders found."
          title="Exchanges"
        />
      </div>
    </PageWrapper>
  );
};

export default Exchanges;
